/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    // caption
    --caption-font-size: var(--imported_caption_font_size, 12px);
    --caption-font-weight: var(--imported_caption_font_weight, 400);
    --caption-color: var(--imported_caption_color, #{$black});
    --caption-line-height: var(--imported_caption_line_height, 20px);
    --caption-text-transform: var(--imported_caption_text_transform, none);

    // caption mobile
    --caption-font-size-mobile: var(--imported_captionMobile_font_size, 12px);
    --caption-font-weight-mobile: var(--imported_captionMobile_font_weight, 400);
    --caption-color-mobile: var(--imported_captionMobile_color, #{$black});
    --caption-line-height-mobile: var(--imported_captionMobile_line_height, 16px);
    --caption-text-transform-mobile: var(--imported_captionMobile_text_transform, none);

    // header height
    --header-total-height: 80px;
}

* {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-family: inherit;
    outline: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    // backface-visibility: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;

    @media print {
        /* stylelint-disable-next-line declaration-no-important */
        background: transparent !important;
        /* stylelint-disable-next-line declaration-no-important */
        color: #000000 !important;
        /* stylelint-disable-next-line declaration-no-important */
        filter: none !important;
        /* stylelint-disable-next-line declaration-no-important */
        text-shadow: none !important;
    }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
label,
summary {
    display: block;
}

header,
footer,
main {
    font-size: 16px;

    @include mobile {
        font-size: 14px;
    }
}

main {
    --border-width: 1px;

    min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
    max-width: 100vw;

    @include mobile {
        min-height: calc(100vh - var(--header-total-height) - var(--navigation-tabs-height));
        margin-block-start: var(--header-total-height);
        margin-block-end: var(--footer-total-height);
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    margin-block-end: 14px;

    @include mobile {
        margin-block-end: 17px;
    }
}

img {
    width: 100%;
}

html {
    font-size: $font-standard-size;
}

body {
    font-family: $font-muli;
    color: $text-color-1;
    scroll-behavior: smooth;
    background-color: #fff;
}

a {
    color: $brown-color-1;
    text-decoration: inherit;

    &:hover {
        cursor: pointer;
    }
}

h1 {
    color: $text-color-1;
    font-size: 54px;
    font-style: normal;
    font-weight: 500;
    line-height: 60px;
    
    @include mobile {
        font-size: 38px;
        line-height: 46px;
    }

    + h2,
    + h3,
    + h4 {
        margin-block-start: 7px;

        @include mobile {
            margin-block-start: 8px;
        }
    }
}

h2 {
    color: $text-color-1;
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: 55px;
    margin-block-end: 18px;
    margin-block-start: 22px;

    @include mobile {
        font-size: 32px;
        line-height: 38px;
        margin-block-end: 21px;
        margin-block-start: 25px;
    }

    + h3,
    + h4 {
        inset-block-start: -9px;
        margin-block-start: 7px;
        margin-block-end: 7px;

        @include mobile {
            inset-block-start: -9px;
            margin-block-start: 8px;
            margin-block-end: 8px;
        }
    }
}

h3 {
    color: $text-color-1;
    font-size: 37px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    margin-block-start: 22px;
    margin-block-end: 18px;


    @include mobile {
        font-size: 24px;
        line-height: 30px;
        margin-block-start: 25px;
        margin-block-end: 21px;
    }

    + h4 {
        inset-block-start: -9px;
        margin-block-start: 7px;
        margin-block-end: 7px;

        @include mobile {
            inset-block-start: -9px;
            margin-block-start: 8px;
            margin-block-end: 8px;
        }
    }
}

h4 {
    color: $text-color-1;
    font-size: 31px;
    line-height: 38px;
    font-style: normal;
    font-weight: 500;
    margin-block-start: 22px;
    margin-block-end: 18px;


    @include mobile {
        font-size: 20px;
        line-height: 26px;
        margin-block-start: 25px;
        margin-block-end: 21px;
    }
}

h5 {
    color: $text-color-1;
    font-size: 26px;
    line-height: 32px;
    font-style: normal;
    font-weight: 500;
    margin-block-start: 22px;
    margin-block-end: 18px;


    @include mobile {
        font-size: 20px;
        line-height: 26px;
        margin-block-start: 25px;
        margin-block-end: 21px;
    }
}

h6 {
    color: $text-color-1;
    font-size: 22px;
    line-height: 28px;
    font-style: normal;
    font-weight: 500;
    margin-block-start: 22px;
    margin-block-end: 18px;


    @include mobile {
        font-size: 20px;
        line-height: 26px;
        margin-block-start: 25px;
        margin-block-end: 21px;
    }
}

p,
li,
dl {
    color: $text-color-1;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px;

    @include mobile {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
    }
}

li {
    list-style-type: none;
    list-style-position: inside;
    margin-block-end: 6px;

    // override default blue color for lists in Safari since iOS15

    & * {
        color: $text-color-1;
    }

    ol & {
        counter-increment: list;

        @include mobile {
            counter-increment: list;
        }
    }

    &:last-child {
        margin-block-end: 14px;
    }
}

p {
    margin-block-end: 14px;

    @include mobile {
        margin-block-end: 17px;
        word-break: break-word;
    }

    &.caption {
        color: $text-color-2;
        font-size: var(--caption-font-size);
        font-style: var(--caption-font-style);
        font-weight: var(--caption-font-weight);
        line-height: var(--caption-line-height);
        text-transform: var(--caption-text-transform);

        @include mobile {
            color: $text-color-2;
            font-size: var(--caption-font-size-mobile);
            font-style: var(--caption-font-style-mobile);
            font-weight: var(--caption-font-weight-mobile);
            line-height: var(--caption-line-height-mobile);
            text-transform: var(--caption-text-transform-mobile);
        }
    }
}

input,
select,
textarea {
    min-height: 48px;
    min-width: 48px;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
}

textarea {
    resize: none;

    @include mobile {
        width: 100%;
    }
}

mark {
    margin-inline-end: 5px;
}

iframe {
    max-width: 100%;
}

a,
input,
button,
textarea,
select,
details {
    @include mobile {
        touch-action: manipulation;
    }
}
