/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ModalBase {
    &-Backdrop {
        display: flex;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.3);
        z-index: 100;
        justify-content: center;
        align-items: center;
        
        @include mobile {
          align-items: flex-end;
        }
    }
    &-OuterWrapper {
        max-width: 800px;
        background: $white;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        @include mobile {
          width: 100%;
        }
    }
    
    &-InnerWrapper {
        background: $white;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        @include mobile {
          border-radius: 4px 4px 0 0;
          min-height: 420px;
          padding: 16px 14px;
      }
    }
    
    &-Header {
      position: relative;
      min-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 0;
    }
    
    &-Title {
      margin: 0;
      font-size: 24px;
    }
    
    &-CloseButton {
      position: absolute;
      top: 50%;
      right: -8px;
      transform: translate(-50%, -50%);
      
    }
    
    &-Content {
      padding: 20px;
      min-height: unset;
      min-width: unset;
      max-height: unset;
      max-width: unset;
      
      @include mobile {
        margin: 0;
      }
    }
    
    &-Actions {
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
    
    &-SaveButton {
      &_FullSize {
        width: 100%;
      }
    }
}