$landing-page-background: #F3F4F6;
$landing-box-shadow: #00000033;
$landing-box-shadow-product: #00000014;
$landing-color-white: #ffffff;
$warning-background: #FEF2F2;
$warning-text-color: #7F1D1D;
$figcaption-background: #FFFFFFA6;
$subTitleColor: #949494;
$tab-unactive: #b2b2b2;
$tab-active-border: #E0640A;


[dir="ltr"] .LandingPage {
    width: 100%;

    &-FullWidthBox,
    &-FullWidthBoxBackground {
        width: 100vw;
        position: relative;
        margin-left: -50vw;
        left: 50%;
        background: $landing-page-background;
        margin-bottom: 30px;
    }

    &-FullWidthBoxWrapper {
        max-width: var(--content-wrapper-width);
        margin-left: auto;
        margin-right: auto;
        padding: 80px 32px;

        .Image-Box {
            position: relative;
            max-width: 555px;

            &::before {
                content: '';
                width: 250px;
                height: 250px;
                border-radius: 125px;
                background: $orange-minicart-color;
                display: block;
                position: absolute;
                z-index: 1;
                bottom: -30px;
                left: -90px;
            }

            &:after {
                content: '';
                width: 135px;
                height: 135px;
                background: $orange-minicart-color;
                border-radius: 70px;
                display: block;
                position: absolute;
                z-index: 1;
                top: -55px;
                right: -55px;
            }

            img {
                z-index: 2;

                &:last-child {
                    max-width: 200px;
                    position: absolute;
                    right: -95px;
                    top: -40px;

                    @include mobile {
                        display: none;
                    }
                }

            }
        }
    }

    &-AddProduct-Fixed {
        position: fixed;
        bottom: 0;
        width: 100vw;
        margin-left: -50vw;
        left: 50%;
        box-shadow: 0px -3px 35px 0px $landing-box-shadow;
        height: 88px;
        padding: 0;
        background: $landing-color-white;
        line-height: 88px;
        z-index: 10;

        @include mobile {
            display: none;
        }
    }

    &-ProductAddToCart {
        max-width: var(--content-wrapper-width);
        padding-left: 32px;
        padding-right: 32px;
        margin-left: auto;
        margin-right: auto;
    }

    &-Boxes {
        display: flex;
        justify-content: space-between;

        @include tablet {
            flex-direction: column-reverse;
        }

        @include mobile {
            flex-direction: column-reverse;
        }

        &Special {
            display: flex;
            justify-content: space-between;

            @include mobile {
                flex-direction: column;
            }
        }

        &.NormalBoxes {
            @include tablet {
                flex-direction: row;
            }

            @include mobile {
                flex-direction: column;
            }
        }
    }

    &-Box {
        max-width: 670px;
        width: 100%;

        &:first-of-type {
            margin-right: 20px;
            z-index: 3;

            @include tablet {
                margin-right: auto;
            }

            @include mobile {
                margin-right: auto;
            }
        }

        &:last-of-type {
            margin-left: 20px;

            @include tablet {
                margin-left: auto;
                margin-bottom: 25px;
            }

            @include mobile {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 25px;
            }
        }

        @include tablet {
            justify-content: center;
            margin: 0 auto;
        }
    }

    h1 {
        color: $text-color-2;
    }

    h3 {
        span {
            color: $crumb-active;
        }

        @include mobile {
            font-size: 25px;
        }
    }

    h5 {
        margin: 10px 0 20px;
    }

    &-List {
        width: 100%;

        li {
            color: $text-color-3;
            line-height: 29px;
            font-size: 18px;
            display: block;
            padding-left: 30px;

            &::before {
                content: '';
                background: url('../icons/checkmark-green.svg') no-repeat 0 0;
                margin: 0;
                padding-right: 24px;
                width: 24px;
                height: 24px;
                left: 0;
                margin-left: -30px;
            }
        }
    }

    ul.LandingPage-List {
        li {
            &::before {
                top: 8px;
                margin-left: -30px;
            }
        }
    }

    &-Buttons {
        @include tablet {
            margin: 0 auto;
            text-align: center;
        }

        a {
            &:first-of-type {
                margin-right: 20px;

                @include mobile {
                    margin-bottom: 15px;
                }
            }
        }

        .Button-Secondary {
            background: $white;
            border: 2px solid $primary-color-2;
            color: $primary-color-2;

            &:hover {
                color: $white;
                background: $primary-color-2;
            }
        }
    }

    .Image-HeightFull {
        height: auto;
    }

    &-Counters {
        margin: 0 0 30px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include desktop {
            margin: 0 15% 30px;
            flex-direction: row;
        }

        &Element {
            color: $text-color-3;
            text-align: center;
            font-size: 18px;
            line-height: 29px;

            @include mobile {
                margin-bottom: 25px;
            }

            &>div {
                font-size: 54px;
                line-height: 64px;
                font-weight: 700;
                color: $crumb-active;
            }
        }
    }

    &-FullWidthBoxTitle {
        text-align: center;
        line-height: 54px;
        font-size: 45px;
        font-weight: 700;
        color: $text-color-1;
        margin-bottom: 20px;
    }

    .FlexBox {
        display: flex;
        padding: 0 32px;
        max-height: 246px;
        height: 100%;
        align-items: center;

        img {
            min-width: 230px;
        }

        p {

            margin-left: 30px;
            font-size: 37px;
            line-height: 48px;
            color: $text-color-2;
            font-weight: 400;
        }

        @include mobile {
            max-height: unset;
            flex-direction: column;

            img {
                margin: 0 auto 25px;
            }

            p {
                font-size: 26px;
                margin-left: 0;
                line-height: 37px;
                text-align: center;
            }
        }

    }

    &-TextHighlight {
        background-color: $orange-minicart-color;
    }

    &-Icons {
        display: flex;
        justify-content: space-between;
        margin: 0 5%;

        @include mobile {
            flex-direction: column;
            margin: 0;
        }
    }

    &-IconItem {
        text-align: center;
        padding: 0;
        color: $text-color-2;
        font-size: 15px;
        max-width: 280px;

        @include mobile {
            margin: 0 auto 25px;
        }

        &>img {
            max-width: 100px;
            margin: 0 auto;
        }

        &>span {
            display: inline-block;
            margin-top: 10px;
        }
    }

    &-BoxTitle {
        text-align: center;
        margin-bottom: 30px;
        font-weight: 700;
        font-size: 45px;
        line-height: 54px;

        @include mobile {
            font-size: 35px;
            line-height: 45px;
        }
    }

    &-BoxSubTitle {
        text-align: center;
        margin-top: -20px;
        margin-bottom: 25px;
        color: $text-color-3;
        font-size: 18px;
        font-weight: 400;
    }

    &-BoxSpecial {
        width: 50%;
        background: $grey-color-14;
        padding: 20px;

        &:first-of-type {
            margin-right: 10px;

            @include mobile {
                margin-right: auto;
                margin-bottom: 20px;
                width: 100%;
            }
        }

        &:last-of-type {
            margin-left: 10px;

            @include mobile {
                margin-left: auto;
                width: 100%;
            }
        }

        p {
            font-size: 18px;
            line-height: 29px;
            color: $text-color-3;
        }
    }

    &-TextWithMargins {
        color: $text-color-3;
        font-size: 18px;
        line-height: 29px;
        color: $text-color-3;
        margin: -15px 13% 35px;
        text-align: center;
    }

    table {
        min-width: 700px;
        width: 100%;

        thead {
            tr {
                th {
                    background-color: $grey-color-7;
                    color: $white;
                    height: 40px;
                    line-height: 40px;
                    font-size: 15px;
                    font-weight: 700;
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 15px;
                    color: $text-color-3;
                    min-height: 44px;
                    line-height: 28px;
                }

                &:nth-of-type(even) {
                    background: $grey-color-14;
                }
            }
        }
    }

    &-ImageItems {
        display: flex;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
        }
    }

    &-ImageItem {
        max-width: 32.5%;

        @include mobile {
            max-width: unset;
            width: 100%;
        }

        &>p {
            font-size: 18px;
            line-height: 29px;
            color: $text-color-3;
            text-align: center;
        }
    }

    &-Boxes {
        display: flex;
        margin-bottom: 45px;

        @include mobile {
            margin-bottom: 25px;
        }
    }

    &-BoxSmall {
        width: 50%;
        padding: 10px 40px;

        @include tablet {
            padding: 0;
        }

        @include mobile {
            width: 100%;
            padding: 10px 0;

            h3 {
                margin: 0;
            }
        }

        &>p {
            font-size: 18px;
            line-height: 29px;
            color: $text-color-3;
        }

        &.Image-Box {
            padding: 0;
            position: relative;

            img {
                &:nth-of-type(2) {
                    position: absolute;
                }
            }

            &.Box-Right {
                margin-left: 20px;

                @include mobile {
                    margin-left: 0;
                }

                img {
                    &:nth-of-type(2) {
                        width: 153px;
                        right: -35px;
                        top: -7px;

                        @include tablet {
                            width: 109px;
                        }
                    }
                }
            }

            &.Box-Left {
                margin-right: 20px;

                @include mobile {
                    margin-right: 0;
                }

                img {
                    &:nth-of-type(2) {
                        width: 171px;
                        left: -35px;
                        top: -7px;

                        @include tablet {
                            width: 122px;
                        }
                    }
                }
            }
        }

        &.Box-With-List {
            padding: 0 40px;

            @include tablet {
                padding: 0;
            }

            @include mobile {
                padding: 10px 0 0;
            }
        }
    }


    &-Tags {
        display: flex;
        width: 50%;
        margin: auto 0 auto 20px;
        flex-wrap: wrap;
        justify-content: flex-start;

        @include mobile {
            width: 100%;
            margin: 20px 0 0 0;
        }
    }

    &-TagItem {
        padding: 10px 30px;
        background: $orange-minicart-color;
        border-radius: 20px;
        height: 49px;
        float: left;
        line-height: 29px;
        margin: 0 20px 15px 0;
        font-size: 18px;
        color: $text-color-1;
    }

    &-ProductActions {
        width: 100%;
        padding: 12px;
        box-shadow: 2px 2px 44px 0px $landing-box-shadow-product;
        background: $white;
        margin-bottom: 40px;

    }

    &-ProductActions {
        .LandingPage {
            &-Product {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0;

                @include mobile {
                    flex-direction: column;
                }

                &Image {
                    max-width: 450px;
                    width: 100%;
                    margin-right: 20px;

                    &Thumbnail {
                        display: none;
                    }
                }

                &ActionsWrapper {
                    h3 {
                        color: $text-color-2;
                        line-height: 48px;
                        margin: 0 0 10px;
                        font-weight: 700;
                    }
                }

                .Button {
                    max-width: 300px;
                    min-width: 240px;
                }

                .Field_type_numberWithControls {
                    margin: 0 20px 0 0;
                }

                .ProductActions-AddToCartWrapper {
                    display: flex;

                    @include mobile {
                        margin-top: 20px;
                        flex-direction: column;
                    }

                    .Field-Wrapper {
                        margin-bottom: 15px;

                        @include mobile {
                            display: flex;
                            justify-content: center;
                        }

                        .Field_type_numberWithControls {
                            @include mobile {
                                margin: 0;
                            }
                        }
                    }
                }

                .ProductPrice {
                    @include mobile {
                        margin-top: 20px;
                    }
                }
            }
        }

        .ProductPrice {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            margin-bottom: 20px;

            .ProductPrice {
                &-Price {
                    font-weight: 600;
                    font-size: 26px;
                    line-height: 36px;
                    margin-right: 30px;
                }

                &-SubPrice {
                    font-size: 15px;
                    line-height: 24px;
                }
            }

            &_hasDiscount {
                margin-bottom: 60px;
            }
        }

        .FieldSelect-Options {
            position: absolute;
        }

        .CmsBlock-Wrapper {
            margin: 15px 0;
        }

        .ProductConfigurableAttributes {
            padding-top: 0;
        }
    }

    &-AddProduct-Fixed {
        @include desktop {
            display: block;
        }

        display: none;

        .LandingPage {
            &-Product {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0;

                &Image {
                    max-width: 60px;
                    width: 100%;
                    margin-right: 20px;


                    &Normal {
                        display: none;
                    }

                    &Thumbnail {
                        height: 100%;
                        max-height: 60px;
                        display: flex;
                        align-items: center;
                        margin: 15px 0;
                    }
                }

                &ActionsWrapper {
                    h3 {
                        color: $text-color-2;
                        font-weight: 600;
                        font-size: 20px;
                        margin: 0;
                    }

                    .Product-Title {
                        flex: 1;
                    }

                    .ProductPrice {
                        display: none;
                    }
                }
            }

            &-ProductActionsWrapper {
                display: flex;
                max-width: calc(100% - 80px);
                width: 100%;
                justify-content: space-between;
                align-items: center;
            }
        }

        .ProductActions-AddToCartWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 0;
        }

        .Field {
            margin: 0 20px 0 0;
            display: flex;
        }

        .ProductActions-AttributesWrapper {
            .ProductConfigurableAttributes {
                margin-bottom: 0;
                padding-bottom: 0;
                padding-top: 0;

                &>div {
                    display: flex;
                }

                .Field {
                    min-width: 250px;
                }

                .FieldSelect-Options {
                    top: 0;
                    position: absolute;
                    top: -130px;
                    overflow: scroll;
                    height: 130px;
                    border-bottom: none;
                    border-top: 1px solid $crumb-active;

                    .FieldSelect-OptionsWrapper_isExpanded {
                        overflow-y: scroll;
                        max-height: 130px;
                    }
                }
            }
        }

        .ProductConfigurableAttributes-Title {
            margin: 0 10px 0 0;
            line-height: 48px;
        }

        .AddToCart {
            padding: 0 20px;
            max-width: 250px;
        }
    }

    &.VersionTwo {
        .LandingPage {
            &-FullWidthBoxWrapper {
                .Image-Box {
                    img {
                        &:last-child {
                            position: unset;
                            max-width: 99px;
                            float: right;

                            @include mobile {
                                display: block;
                            }
                        }
                    }
                }
            }

            &-Counters {
                margin-left: 5%;
                margin-right: 5%;

                &Element {
                    @include mobile {
                        img {
                            max-width: 50%;
                        }
                    }
                }
            }

            &-BoxSmall {
                &>p {
                    color: $text-color-2;
                }
            }

            &-AlertBox {
                margin: 45px -40px 0;
                background: $warning-background;
                padding: 12px;
                display: flex;

                @include mobile {
                    margin: 25px 0 0;
                }

                &Text {
                    color: $warning-text-color;
                    font-size: 18px;
                    line-height: 29px;
                    padding: 0 10px;
                }
            }

            &-BlockInside {
                padding: 60px 20px;

                div {
                    padding: 0 10%;
                    text-align: center;

                    p {
                        margin: 0;
                    }
                }
            }

            &-BigIcon {
                width: 92px;
                position: absolute;
                top: -46px;
                left: calc((100% / 2) - 46px);
            }

            &-TextHighlight {
                background: transparent;
            }
        }

        h3 {
            span {
                color: $text-color-1;
            }
        }

        .PositionRelative {
            position: relative;
        }

        .WithBackground {
            background: $grey-color-14;
            margin: 100px 0 45px;
        }

        .Slider {
            min-height: 500px;

            &Widget {
                &-Figure {
                    position: relative;
                    min-height: 500px;

                    .Image {
                        min-height: 500px;
                    }
                }

                &-Figcaption {
                    position: absolute;
                    top: 40px;
                    left: 0;
                    border-radius: 0 8px 8px 0;
                    padding: 10px 10px 0 40px;
                    font-size: 18px;
                    line-height: 29px;
                    font-weight: 700;
                    color: $text-color-1;
                    background: $figcaption-background;
                    width: auto;
                }

                .Slider-Crumbs {
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 0;
                    margin-top: 30px;
                }
            }
        }
    }

    &.VersionThree {
        .LandingPage {
            &-TwoColumnBlock {
                display: flex;
                margin-bottom: 40px;

                @include mobile {
                    flex-direction: column;
                }

                .ImageBackground {
                    max-width: 660px;
                    max-height: 450px;
                    display: block;
                    background: $landing-page-background;
                    width: 100%;
                    height: 100%;

                    @include mobile {
                        margin: 0 auto 10px;
                    }
                }
            }

            &-RightColumn {
                display: flex;
                margin: auto 20px;
                flex-direction: column;

                h3 {
                    color: $text-color-2;
                }
            }

            &-ImageItems {
                margin-bottom: 40px;

                p {
                    font-size: 15px;
                    line-height: 24px;
                    color: $text-color-3;
                }

                ul {
                    li {
                        font-size: 15px;
                    }
                }

                h5 {
                    text-align: center;
                    margin-bottom: 10px;
                }

                .LandingPage-SubTitle {
                    text-align: center;
                    width: 100%;
                    color: $subTitleColor;
                    font-size: 18px;
                    font-weight: 700;
                    display: inline-block;
                    margin-bottom: 20px;
                }

                .ImageBackground {
                    display: block;
                    background: $landing-page-background;
                    width: 100%;
                    height: 100%;
                    max-height: 185px;
                    margin-bottom: 20px;
                }
            }

            &-ImageItem {
                &:nth-of-type(1) {
                    img {
                        width: 135px;
                        height: 153px;
                        margin: 20px auto;
                        display: flex;
                    }
                }

                &:nth-of-type(2) {
                    img {
                        width: 243px;
                        height: 97px;
                        margin: 44px auto;
                        display: flex;
                    }
                }

                &:nth-of-type(3) {
                    img {
                        width: 425px;
                        height: 134px;
                        margin: 25px auto;
                        display: flex;
                    }
                }
            }

            &-SubTitle {
                text-align: center;
                width: 100%;
                display: inline-block;
                font-size: 18px;
                line-height: 29px;
                color: $text-color-3;
                top: -20px;
            }

            &-Box-ThreeColumns {
                display: flex;
                margin-bottom: 40px;

                @include mobile {
                    flex-direction: column;
                }

                &>div {
                    width: 33%;

                    @include mobile {
                        width: 100%;
                    }
                }

                .LandingPage-Image {
                    @include mobile {
                        max-width: 300px;
                        margin: 20px auto;
                        display: flex;
                    }
                }
            }

            &-TableSmall {
                min-width: unset;

                tr {
                    &:nth-of-type(even) {
                        background: transparent;
                    }

                    &:nth-of-type(odd) {
                        background: $landing-page-background;
                    }
                }
            }

            &-Tabs {
                margin-bottom: 40px;
                display: flex;
                flex-direction: column;

                &>ul {
                    display: flex;
                    flex-direction: row;
                    margin: 0 20px;
                    z-index: 2;

                    @include mobile {
                        margin: 0;
                    }

                    li {
                        min-width: 340px;
                        height: 50px;
                        line-height: 50px;
                        text-align: center;
                        margin-bottom: 0;
                        font-weight: 600;
                        font-size: 22px;
                        color: $tab-unactive;
                        cursor: pointer;

                        @include mobile {
                            min-width: 140px;
                            font-size: 15px;
                        }

                        &:before {
                            display: none;
                        }

                        img {
                            width: 36px;
                            height: 36px;
                            top: 6px;

                            @include mobile {
                                display: none;
                            }
                        }

                        &.active {
                            border-bottom: 5px solid $tab-active-border;
                            border-radius: 4px;
                            color: $text-color-1;
                        }
                    }
                }
            }

            &-TabsWrapper {
                background: $landing-page-background;
                z-index: 1;
                top: -5px;
                border-radius: 8px;
                padding: 35px 15px;

                .tab-content {
                    display: none;
                    width: 100%;
                }

                .active {
                    display: flex;

                    @include mobile {
                        flex-direction: column;
                    }
                }

                .column {
                    width: 33%;

                    h5>span {
                        color: $tab-active-border;
                    }

                    @include mobile {
                        width: 100%;
                    }
                }
            }

            &-BoxesSpecial {
                display: flex;
                min-height: unset;

                @include desktop {
                    overflow: overlay;
                }

                &-Wrapper {
                    display: flex;

                    @include desktop {
                        flex-direction: row;
                        width: calc(668px * 7);
                    }

                    @include tablet {
                        flex-wrap: wrap;
                    }

                    @include mobile {
                        flex-wrap: wrap;
                    }
                }

                .LandingPage-BoxSpecial {
                    margin: 0 10px 20px;

                    @include desktop {
                        width: 658px;
                    }

                    @include tablet {
                        width: calc(50% - 20px);
                    }

                    @include mobile {
                        width: 100%;
                    }

                    &:first-of-type {
                        margin-left: 0;

                        @include tablet {
                            margin-left: 10px;
                        }

                        @include mobile {
                            margin-left: 10px;
                        }
                    }

                    &:last-of-type {
                        margin-right: 0;

                        @include tablet {
                            margin-right: 10px;
                        }

                        @include mobile {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    &.VesrionGreen {
        .LandingPage-FullWidthBox {
            width: 100vw;
            background: transparent;
            background-size: cover !important;
            background-position: 44% 100% !important;

            @include mobile {
                background-position: 72% 100% !important;
            }

            &.mobile-background {
                background-position: 45% 100% !important;
            }

            h1 {
                max-width: 350px;
                margin-block-end: 25px;

                @include mobile {
                    max-width: unset;
                    text-align: center;
                }
            }

            .LandingPage-Box {
                max-height: 620px;
                height: 100%;
                margin: 100px 0;
            }

            .LandigPage-Description {
                font-size: 22px;
                margin-block-end: 25px;

                @include mobile {
                    text-align: center;
                }
            }

            .LandingPage-Button {
                @include mobile {
                    margin-bottom: 15px;
                }
            }
        }

        .LandingPage-BoxesSpecial {
            overflow: hidden;
        }

        .ProductList.ProductListWidget {
            margin-block-end: 0;

            nav {
                display: none;
            }

            .slick-next {
                right: 55px;
            }
        }

        .LandingPage-FullWidthBoxTitle {
            font-size: 37px;
        }

        .BigMargin {
            li {
                margin-bottom: 25px;
            }
        }

        .LandingPage-TwoColumnBlock {
            font-size: 18px;
            line-height: 28px;
        }

        .LandingPage-LeftColumn {
            max-width: 660px;
            max-height: 450px;
            display: block;
            width: 100%;
            height: 100%;

            span {
                display: block;
                margin: 10px 0 25px;

                &:first-of-type {
                    margin-top: 40px;
                }
            }
        }

        .FlexList ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            li {
                &:before {
                    content: '';
                }

                display: flex;
                flex-direction: column;
                width: 33.3%;
                margin: 0 0 25px;

                @include mobile {
                    width: 100%;
                }

                h3 {
                    font-size: 26px;
                    width: 100%;
                    text-align: center;
                }

                span {
                    font-size: 15px;
                    line-height: 24px;
                    width: 100%;
                    text-align: center;
                }

                img {
                    max-width: 80px;
                    margin: 0 auto 10px;
                }
            }
        }

        .LandingPage-TwoColumnBlock.ImageBackground {
            background: transparent;
        }

        .LandingPage-BlockBottomText {
            display: flex;
            margin: 30px 0;
            justify-content: center;
        }

        .WithBottomText {
            .LandingPage-BlockBottomText {
                display: flex;
                margin-top: 30px;
                justify-content: center;
                text-align: center;
            }
        }

        .ListWithImages {
            img {
                max-width: 150px;
                display: flex;
                margin: 0 auto;
            }

            span {
                margin: 10px 30px;
                text-align: center;

                &:first-of-type {
                    margin-top: 10px;
                }
            }

            .LandingPage-RightColumn {
                margin: 0;
            }
        }

        .GrayBackground {
            background-color: #F3F4F6;
            margin-top: 30px;

            .LandingPage-FullWidthBoxWrapper {
                padding: 10px 32px;
            }
        }

        .LandingPage-TableHead {
            display: flex;
            justify-content: space-between;
            font-size: 26px;
            line-height: 34px;
            margin-top: 25px;

            h5 {
                margin: 0;
                font-weight: 600;
            }

            span {
                margin: 0;
                font-weight: 600;

                &:first-of-type {
                    margin-top: 0;
                }
            }

        }

        .Table-Wrapper {
            overflow-x: hidden;
        }

        .LandingPage-Table {
            max-width: 95%;

            @include mobile {
                max-width: 100%;
            }
        }

        .LandingPage-LeftColumn {
            max-width: unset;
            width: 50%;
        }

        .ProductList.ProductListWidget {
            padding: 0;
        }

        .LandingPage-RightColumn {
            width: 50%;
        }

        .LandingPage-LeftColumn {
            margin: auto 20px;
        }

        table tbody tr:nth-of-type(even) {
            background: transparent;
        }

        table tbody tr:nth-of-type(odd) {
            background: #F3F4F6;
        }

        .LandingPage-LeftColumn.Table {
            margin: 0;
        }

        .LandingPage-LeftColumn {
            @include mobile {
                width: 100%;
                margin: 0;
            }
        }

        .LandingPage-RightColumn {
            @include mobile {
                width: 100%;
                margin: 0;
            }

            .LandingPage-Table {
                &:first-of-type {
                    @include mobile {
                        margin-top: 100px;
                    }
                }
            }
        }
    }

    &.Coveron-LP {
        margin-top: -35px;

        .AddToCart {
            @include desktop {
                min-width: 320px;
            }
        }

        .Field_type_numberWithControls {
            button {
                border-color: $primary-color-2;

                &:disabled {
                    svg {
                        fill: $grey-color-15;
                    }

                    border-color: $grey-color-15;
                }
            }

            input {
                font-size: 15px;
                font-weight: 400;
            }
        }

        .LandingPage {
            &-AdditionalInfo {
                align-items: center;
                color: $text-color-2;
                display: flex;
                font-size: 18px;
                line-height: 28.8px;
                margin-bottom: 64px;

                .LandingPage-ProductImage {
                    height: 94px;
                    width: 99px;
                }
            }

            @include mobile {
                &-Box {
                    align-items: center;
                    display: flex;
                    flex-direction: column;

                    .RenderWhenVisible {
                        align-items: center;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 20px;
                        justify-content: center;

                        .Button-Primary {
                            margin-right: 0;
                        }
                    }
                }
            }


            &-BoxesInfo {
                font-size: 18px;
                line-height: 28.8px;
                margin-bottom: 78px;
                text-align: center;
            }

            &-BoxTitle {
                &_isCursive {
                    font-style: italic;
                }
            }

            &-BoxSubTitle {
                font-size: 15px;
                line-height: 24px;
                margin: 0 auto;
                margin-bottom: 60px;
                max-width: 928px;
            }

            &-Buttons {
                .Button {

                    &-Primary,
                    &-Secondary {
                        padding: 14px 25px;
                        width: 210px;
                    }
                }
            }

            &-BuyNow {
                margin-bottom: 141px;
            }

            &-Counters {
                align-items: center;
                margin: 56px 15% 86px;
            }


            &-CropTab,
            &-GardenTab {
                display: grid;
                gap: 20px;
                grid-template-columns: repeat(3, 1fr);

                @include mobile {
                    grid-template-columns: 1fr;
                }

                ul {
                    li {
                        padding-left: 24px;

                        &::before {
                            left: 9px;
                            margin-left: 0;
                            position: absolute;
                        }
                    }
                }

                p {
                    margin-bottom: 0;
                }
            }

            &-Decor {
                background-color: $primary-color-1;
                border-radius: 8px;
                bottom: -10px;
                height: 9.64px;
                position: absolute;
                transition: all 0.2s ease-in-out;
                width: 340px;
                z-index: 2;

                @include mobile {
                    width: 50%;
                }
            }

            &-Description {
                color: $text-color-3;
                font-size: 22px;
                font-weight: 600;
                margin-bottom: 40px;
            }

            &-Dots {
                display: flex;
                justify-content: center;
            }

            &-Dot {
                background-color: $grey-color-15;
                border-radius: 8px;
                cursor: pointer;
                display: inline-block;
                height: 8px;
                margin: 0 6px;
                transition: background-color 0.3s ease-in-out;
                width: 8px;
                &_active {
                    background-color: $primary-color-1;
                }
            }

            &-FullWidthBox {
                h1 {
                    font-weight: 700;
                    margin-bottom: 4px;
                }

                @include mobile {
                    background-position: 7% 100% !important;

                    &Wrapper {
                        padding: 0;
                    }
                }
            }

            &-HowItWorks {
                background-color: $grey-color-14;
                border-radius: 8px;
                font-size: 15px;
                line-height: 24px;
                padding: 16px;

                img {
                    border-radius: 4px;
                    margin-bottom: 20px;
                }
            }

            &-Important {
                background-color: $grey-color-14;
                margin-top: 109px;
                margin-bottom: 78px;
                padding: 75px 0 46px;

                &Wrapper {
                    padding: 0 32px;

                    h2 {
                        margin-bottom: 60px;
                        margin-top: 0;
                    }

                    ul {
                        width: 50%;

                        @include mobile {
                            width: 100%;
                        }

                        li {
                            padding-left: 34px;
                            margin-bottom: 30px;

                            &::before {
                                left: 0;
                                margin: 0;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }

            &-Ingredients {
                margin-bottom: 235px;
                margin-top: 160px;

                @include mobile {
                    margin-bottom: 64px;
                    margin-top: 64px;
                }

                h2 {
                    font-size: 45px;
                    font-weight: 700;
                    line-height: 54px;
                }

                &Container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                &List {
                    li {
                        font-size: 18px;
                        line-height: 28.8px;

                        @include mobile {
                            font-size: 14px;
                        }
                    }

                    sup {
                        vertical-align: super;
                    }

                    >li {
                        font-size: 18px;
                        line-height: 28.8px;

                        @include mobile {
                            font-size: 14px;
                        }

                        ul {
                            margin-left: 34px;
                        }

                        &::before {
                            background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwIDZMOSAxN0w0IDEyIiBzdHJva2U9IiMyMkM1NUUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==");
                            background-repeat: no-repeat;
                            content: '';
                            display: inline-block;
                            height: 24px;
                            margin-left: 0;
                            top: 6px;
                            width: 24px;
                        }
                    }

                    &Lab {
                        margin-top: 20px;

                        li {
                            color: $text-color-2;
                            font-size: 18px;
                            line-height: 28.8px;
                            padding-left: 20px;

                            &::before {
                                font-size: 18px;
                                left: 0;
                                margin-left: 0;
                                position: absolute;
                                top: 0;
                            }
                        }
                    }
                }
            }

            &-Lab {
                .LandingPage-TwoColumns {
                    margin-bottom: 60px;
                }

                table {
                    tbody {
                        tr {
                            color: $text-color-3;

                            &:nth-of-type(odd) {
                                background-color: $white;
                            }

                            &:nth-of-type(even) {
                                background-color: $grey-color-14;
                            }

                            td {
                                padding: 10px;
                            }
                        }
                    }

                    thead {
                        tr {
                            th {
                                border-radius: 0;
                                height: auto;
                                line-height: 24px;
                                padding: 10px;
                            }
                        }
                    }
                }
            }

            &-LabPhotos {
                .LandingPage {
                    &-TwoColumns {
                        div {
                            background-color: $grey-color-14;
                            color: $text-color-2;
                            font-size: 15px;
                            line-height: 24px;
                            padding: 16px;

                            img {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }

            &-List,
            &-CoveronList {
                li {
                    align-items: center;
                    color: $text-color-2;
                    display: flex;
                    font-size: 26px;
                    font-weight: 600;
                    margin-bottom: 29px;
                    min-height: 45px;
                    padding-left: 55px;

                    &::before {
                        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNDYiIHZpZXdCb3g9IjAgMCA0NSA0NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM3LjUgMTEuNzVMMTYuODc1IDMyLjM3NUw3LjUgMjMiIHN0cm9rZT0iIzIyQzU1RSIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+");
                        display: inline-block;
                        height: 45px;
                        left: 0;
                        margin: 0;
                        padding-right: 0;
                        position: absolute;
                        width: 45px;
                    }
                }
            }

            &-CoveronList {
                li {
                    color: $text-color-3;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 28.8px;
                    list-style: none;
                    margin-bottom: 10px;
                    padding-left: 34px;

                    &::before {
                        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwIDYuNUw5IDE3LjVMNCAxMi41IiBzdHJva2U9IiMyMkM1NUUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==");
                        content: '';
                        height: 24px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 24px;
                    }
                }
            }

            &-ListItems {
                &:nth-of-type(1) {
                    margin-bottom: 120px;
                }

                ul {
                    display: flex;
                    flex-wrap: nowrap;
                    overflow: auto;
                    scroll-behavior: smooth;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    li {
                        color: $text-color-2;
                        display: flex;
                        flex: 0 0 auto;
                        line-height: 24px;
                        text-align: center;

                        svg {
                            margin: 0 auto;
                        }

                        p {
                            line-height: 24px;

                            @include mobile {
                                font-size: 12px;
                            }
                        }

                        .Italic {
                            font-style: italic;
                            width: auto;
                        }
                    }
                }
            }

            &-Product {
                .ProductActions {
                    &-AddToCartWrapper {
                        align-items: flex-start;

                        @include mobile {
                            flex-direction: column;

                            .AddToCart {
                                min-width: 100%;
                            }

                            .Field-Wrapper {
                                margin: 10px auto;
                            }
                        }
                    }
                }
            }

            &-ProductActions {
                margin-bottom: 114px;
            }

            &-Review {
                align-items: center;
                display: flex;
                flex: 0 0 auto;
                flex-direction: column;
                justify-content: flex-start;
                width: calc(50% - 10px);

                @include mobile {
                    width: 80%;
                }

                &::marker,
                &::before {
                    display: none;
                }

                img {
                    margin-bottom: 20px;
                    max-width: 80px;
                }

                p {
                    color: $text-color-2;
                    font-size: 15px;
                    line-height: 24px;
                    text-align: center;
                }

                &s {
                    margin-bottom: 133px;

                    ul {
                        display: flex;
                        flex-wrap: nowrap;
                        gap: 20px;
                        overflow: auto;

                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                }
            }

            &-RightColumn {
                margin-left: 0;
                margin-right: 0;
            }

            &-Tab {
                background-color: $grey-color-14;
                border-radius: 8px;
                color: $text-color-2;
                display: none;
                padding: 45px 20px 27px;
                overflow: hidden;
                transition: all 0.3s ease-in-out;

                h5,
                li,
                strong,
                p {
                    color: $text-color-2;
                }
            }

            &-Tabs {
                margin-bottom: 30px;

                .LandingPage-BoxTitle {
                    margin-bottom: 60px;
                }

                >div {
                    display: flex;
                    font-size: 22px;
                    font-weight: 600;
                    margin-left: 19px;
                    padding-bottom: 20px;

                    @include mobile {
                        margin-left: 0;
                    }

                    label {
                        color: $tab-unactive;
                        cursor: pointer;
                        text-align: center;
                        user-select: none;
                        transition: all 0.2s ease-in;
                        width: 340px;

                        @include mobile {
                            align-items: center;
                            display: inline-flex;
                            justify-content: center;
                            width: 50%;
                        }
                    }
                }

                input[type="radio"] {
                    opacity: 0;
                    position: absolute;
                    z-index: -1;

                    &:checked {
                        &:nth-of-type(1) {
                            ~div label {
                                &:nth-of-type(1) {
                                    color: $text-color-1;

                                }

                                ~.LandingPage {
                                    &-Decor {
                                        transform: translateX(0);
                                    }
                                }
                            }

                            ~.LandingPage-Tab {
                                &:nth-of-type(1) {
                                    display: grid;
                                }
                            }
                        }

                        &:nth-of-type(2) {
                            ~div label {
                                &:nth-of-type(2) {
                                    color: $text-color-1;
                                }

                                ~.LandingPage {
                                    &-Decor {
                                        transform: translateX(340px);

                                        @include mobile {
                                            transform: translateX(100%);
                                        }
                                    }
                                }
                            }

                            ~.LandingPage-Tab {
                                &:nth-of-type(2) {
                                    display: grid;
                                }
                            }
                        }
                    }
                }
            }

            &-TwoColumns {
                display: grid;
                gap: 20px;
                grid-template-columns: repeat(2, 1fr);

                @include mobile {
                    grid-template-columns: 1fr;
                }
            }

            &-Video {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;

                &Container {
                    margin-bottom: 103px;
                    padding-top: 56.25%;
                    width: 100%;
                }
            }
        }

        .Table-Wrapper {
            @include mobile {
                overflow-x: auto;
            }
        }
    }

    &-Wrapper.Carousel {
        margin-bottom: 80px;

        .LandingPage {
            &-ListItems {
                overflow: hidden;

                ul {
                    display: flex;
                    flex-wrap: nowrap;
                    overflow: auto;
                    scroll-behavior: smooth;
                    justify-content: flex-start;
                    bottom: -30px;

                    li {
                        color: $grey-color-4;
                        display: flex;
                        flex: 0 0 auto;
                        line-height: 24px;
                        text-align: center;
                        flex-direction: column;
                        width: 46%;
                        margin: 0 2% 25px;
                        align-items: center;

                        @include mobile {
                            width: 100%;
                            margin: 0 0 20px;
                        }

                        & > svg {
                            margin-bottom: 25px;
                        }

                        p {
                            line-height: 24px;
                        }
                    }
                }
            }

            &-Dots {
                display: flex;
                justify-content: center;
            }

            &-Dot {
                background-color: $grey-color-15;
                border-radius: 8px;
                cursor: pointer;
                display: inline-block;
                height: 8px;
                margin: 0 6px;
                transition: background-color 0.3s ease-in-out;
                width: 8px;
                &_active {
                    background-color: $primary-color-1;
                }
            }
        }
    }
}
