/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MwDownloads {
    &-Item {
        &Description {
            line-height: 24px;
            color: $text-color-1;
            font-size: 15px;
            width: 100%;
        }
    }

    &-LinkContainer {
        margin-bottom: 4px;
    }

    &-ItemContainer {
        display: flex;
        flex-direction: row;
        margin: 10px 0; 
        padding: 10px 0;
        width: 100%;

        &:last-of-type {
            border-bottom: none;
        }
    }

    &-Meta {
        margin-left: 10px;
        font-size: 14px;
    }

    &-Link {
        color: $primary-color-2;
        font-size: 15px;

        &:hover {
            color: $primary-color-1;
        }
    }

    &-Icon {
        width: 40px; 
        height: 40px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > span {
            width: 36px;
            height: 36px;
        }
    }

    &-zip {
        background: url(../../style/icons/files/zip.svg) no-repeat 0 0;
    }

    &-7z {
        background: url(../../style/icons/files/7z.svg) no-repeat 0 0;
    }

    &-doc {
        background: url(../../style/icons/files/doc.svg) no-repeat 0 0;
    }

    &-gif {
        background: url(../../style/icons/files/gif.svg) no-repeat 0 0;
    }

    &-jpg {
        background: url(../../style/icons/files/jpg.svg) no-repeat 0 0;
    }

    &-odt {
        background: url(../../style/icons/files/odt.svg) no-repeat 0 0;
    }

    &-pdf {
        background: url(../../style/icons/files/pdf.svg) no-repeat 0 0;
    }

    &-png {
        background: url(../../style/icons/files/png.svg) no-repeat 0 0;
    }

    &-ppt {
        background: url(../../style/icons/files/ppt.svg) no-repeat 0 0;
    }

    &-rar {
        background: url(../../style/icons/files/rar.svg) no-repeat 0 0;
    }
}