/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
@mixin button-disabled {
    pointer-events: none;
    background-color: $disabled-color;
    border-color: $disabled-color;
    color: $white;
}

@mixin button {
    align-items: center;
    background-color: var(--button-background);
    border-radius: 3px;
    cursor: pointer;
    display: inline-flex;
    color: var(--button-color);
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    justify-content: center;
    height: 48px;
    padding: 14px 30px;
    text-decoration: none;
    text-transform: uppercase;
    transition-duration: .25s;
    transition-timing-function: ease-out;
    transition-property: background-color, color, border, outline;
    will-change: background-color, color, border;

    svg {
        path {
            transition: stroke 0.25s ease-out;
        }
    }

    @include mobile {
        user-select: none;
        width: 100%;
    }

    border: {
        color: var(--button-background);
        style: solid;
        width: 1px;
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:not([disabled]) {
        &:hover {
            @include desktop {
                background-color: var(--button-hover-background);
                border-color: var(--button-hover-border);
                color: var(--button-hover-color);
            }
        }
    }

    &[disabled] {
        @include button-disabled();
    }

    &_secondary {
        background-color: var(--button-hover-background);
        color: var(--button-hover-color);
        border: 0;
        outline: 1px solid var(--button-hover-border);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background-color: var(--button-background);
                    color: var(--button-color);
                    outline: 2px solid var(--button-background);
                }
            }
        }

        &:disabled {
            background-color: transparent;
            color: $disabled-color;
            outline: 2px solid $disabled-color;
        }
    }
    
    &_dark {
        background-color: $background-color-6;
        border-color: $background-color-6;
        color: $white;
        outline: 1px solid $background-color-6;

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background-color: $text-color-2;
                    border-color: $text-color-2;
                    color: $white;
                    outline: 1px solid $text-color-2;
                }
            }
        }

        &:disabled {
            background-color: transparent;
            border-color: $disabled-color;
            color: $disabled-color;
            outline: 1px solid $disabled-color;
        }
    }

    &_isHollow {
        background-color: transparent;
        color: var(--hollow-button-color);
        border: 0;
        outline: 1px solid var(--hollow-button-border);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background-color: transparent;
                    color: var(--hollow-button-hover-color);
                    outline: 1px solid var(--hollow-button-hover-border);
                }
            }
        }

        &:disabled {
            background-color: transparent;
            color: $disabled-color;
            outline: 1px solid $disabled-color;
        }
    }

    &_isWithoutBorder {
        border: 0;
    }

    &_likeLink,
    &_likeSecondaryLink {
        border: none;
        border-bottom: 2px solid var(--like-link-button-border);
        background-color: var(--like-link-button-background);
        color: var(--like-link-button-color);
        height: auto;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
        text-align: start;

        &:active {
            text-decoration: none;
        }

        &:not([disabled]) {

            &:active,
            &:focus,
            &:hover {
                @include desktop {
                    cursor: pointer;
                    border-bottom: 2px solid var(--like-link-button-hover-border);
                    background-color: var(--like-link-button-hover-background);
                    color: var(--like-link-button-hover-color);
                }
            }
        }

        &[disabled] {
            border-bottom: 2px solid $disabled-color;
            color: $disabled-color;
            background-color: var(--like-link-button-background);
        }

        &.Button_medium,
        &.Button_small {
            height: auto;
            padding: 0;
            font-size: 15px;
            line-height: 24px;
        }

        &.Button_small {
            font-size: 12px;
            line-height: 18px;
        }
    }
    &_likeSecondaryLink {
        border-bottom: 2px solid var(--like-secondary-link-button-border);
        background-color: var(--like-secondary-link-button-background);
        color: var(--like-secondary-link-button-color);

        &:not([disabled]) {
            &:active,
            &:focus,
            &:hover {
                @include desktop {
                    border-bottom: 2px solid var(--like-secondary-link-button-hover-border);
                    background-color: var(--like-secondary-link-button-hover-background);
                    color: var(--like-secondary-link-button-hover-color);
                }
            }
        }
    }

    &_medium {
        height: 42px;
        font-size: 15px;
        line-height: 20px;
        padding: 11px 22px;
        font-weight: 700;
    }

    &_small {
        height: 38px;
        font-size: 12px;
        line-height: 16px;
        padding: 11px 18px;
        font-weight: 700;
        text-transform: uppercase;
    }
}
