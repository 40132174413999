/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

%loader {
    position: relative;
    width: 40px;
    height: 23px;
    background-color: $white;
    margin: 12px 0;
    border-inline-start: solid 5px var(--primary-base-color);
    border-inline-end: solid 5px var(--primary-base-color);
    box-sizing: border-box;
    animation: rotate 2s linear infinite;
    transform-origin: 50% 50%;

    &::before,
    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        width: 28px;
        height: 28px;
        transform: scaleY(.5774) rotate(-45deg);
        background-color: inherit;
        inset-inline-start: 1px;
        box-sizing: border-box;
    }

    // ltr + rtl adjusted, don't replace 'left' and 'right' here

    &::before {
        inset-block-start: -14px;
        border-top: solid 7px var(--primary-base-color);
        border-right: solid 7px var(--primary-base-color);
    }

    &::after {
        inset-block-end: -14px;
        border-bottom: solid 7px var(--primary-base-color);
        border-left: solid 7px var(--primary-base-color);
    }

    span {
        position: absolute;
        inset-block: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        margin: auto;
        width: 18px;
        height: 18px;
        background-color: rgb(80, 88, 101);
        border-radius: 50%;
        z-index: 2;
    }
}
