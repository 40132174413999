/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

 :root {
    --footer-content-height: 220px;
    --footer-copyright-height: 50px;
    --footer-nav-height: 48px;
    --footer-total-height: calc(env(safe-area-inset-bottom) + var(--footer-nav-height));
}

.Footer {
    $column-count: 4;

    @include mobile {
        margin-block-end: var(--navigation-tabs-height);
    }

    &-MainWrapper {
        background-color: $background-color-6;
        padding: 0;

        @include mobile {
            padding: 40px 0 20px;
        }
    }

    &-BottomWrapper {
        padding: 16px 0;
        width: 100%;
        max-width: var(--content-wrapper-width-special);
        padding-left: 32px;
        padding-right: 32px;
        margin: 0 auto;
    }

    &-Bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mobile {
            flex-direction: column;
            justify-content: center;
        }

        @include desktop {
            padding: 0 10px;
        }
    }

    &-IconsWrapper {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        grid-gap: 30px;
        gap: 30px;
        @include mobile {
            padding-top: 30px;
            justify-content: center;
            align-items: center;
        }
    }

    &-BottomIcon {
        width: auto;
        max-height: 40px;
    }

    &-Copyright {
        font-size: 12px;
        text-align: center;
        line-height: 16px;

        a {
            color: inherit;
            font-weight: 400;
            font-size: 12px;

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-Content {
        min-height: var(--footer-content-height);
        padding: 40px 0 60px;
    }

    &-Columns {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        max-width: var(--content-wrapper-width-special);

        .CmsBlock-Wrapper {
            width: 100%;
        }

        @include mobile {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        @include tablet {
            padding-inline: 30px;
        }
    }

    &-Column {
        display: flex;
        flex-direction: column;
        padding-inline: 8px;
        max-width: 325px;
        width: 100%;

        &_isNewsletter {
            background: $crumb;
            max-width: 325px;
            padding: 40px 15px;

            .Footer-ColumnTitle {
                font-size: 22px;
                font-weight: 600;
                color: $text-color-1;
                margin-bottom: 60px;
                text-align: center;
            }

            @include mobile {
                width: 100%;
                max-width: unset;
            }
        }

        @include mobile {
            width: 100%;
        }

        &:not(:last-of-type) {
            @include mobile {
                margin-block-end: 30px;
            }
        }
    }

    &-ColumnTitle, &-ColumnSubtitle {
        margin: 8px 0 28px;
        color: $grey-color-4;
        font-size: 15px;
        font-weight: 700;
        line-height: 21px;
        text-transform: none;
        color: $white;

        @include mobile {
            margin-top: 0;
        }
    }

    &-ColumnSubtitle {
        margin-bottom: 8px 0 20px;
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }

        @include mobile {
            align-items: center;
        }
    }

    
    &-ColumnList {
        margin-bottom: 30px;

        @include mobile {
            margin: 0 auto 30px;
        }
    }

    &-Line {
        margin: 0;
    }

    &-Block {
        margin-bottom: 28px;
    }

    &-ColumnItem {
        margin-block-end: 8px;
        border-radius: 2px;
        color: inherit;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;

        &:last-of-type {
            margin: 0;
        }

        
        &::before {
            display: none;
        }

        &:not([class*='_isDisabled']):hover {
            cursor: pointer;
            text-decoration: underline;
        }

        a {
            font-weight: 400;
            font-size: 15px;
            color: $white;
        }

        &Image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                // important required as default 100% width is passed as inline style
                // + you can't achieve same centering with just passing '16px' as inline style for the case
                /* stylelint-disable-next-line declaration-no-important */
                width: 16px!important;
                inset-block-start: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);

                &:dir(rtl) {
                    transform: translate(50%, -50%);
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        &_type_image {
            width: 40px;
            height: 40px;
            border: 1px solid var(--secondary-dark-color);

            &:not(:last-of-type) {
                margin-inline-end: 20px;
            }
        }
    }
    
    &-Row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;

        @include mobile {
            margin-bottom: 0;
        }
    }

    &-ContactBox {
        margin-bottom: 20px;

        @include mobile {
            margin: 20px 0;
        }

        .Footer-Column {
            @include mobile {
                align-items: flex-start;
            }
        }

        .Footer-Row {
            @include mobile {
                margin-bottom: 20px;
            }
        }
    }

    &-ContactText {
        font-size: 15px;
        font-weight: 400;
        color:$white;

        &_Thin {
            font-weight: 400;
            color: $secondary-color-1;
        }
    }

    &-Logo {
        width: 170px;
        height: auto;
        margin: 0 0 20px;
    }

    &-ContactIcon {
        width: auto;
        height: 20px;
        margin-right: -20px;
    }

    &-SocialIcon {
        cursor: pointer;
        width: auto;
        height: 40px;
        transition: scale 0.2s;
        &:hover {
            scale: 1.05;
        }
        &Wrapper {
            margin-bottom: 60px;
            &.Footer-Row {
                grid-gap: 16px;
                gap: 16px;
            }
            @include mobile {
                justify-content: center;
            }
        }
    }

    &-Social {
        ul {
            display: flex;
            
            li {
                margin-right: 15px;
            }
        }
    }

    &-GDPR {
        margin-top: 50px;
        text-align: center;
        width: 100%;
    }
}
