.ContactInfo {
    margin-top: 20px;

    &-Box {
        background: $grey-color-1;
        border-radius: 12px;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        margin-bottom: 42px;

        &:last-of-type {
            margin-bottom: 0;
        }

        ul li::before {
            display: none;
        }
    }

    &-Title {
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        margin: 0 0 20px 0;
        width: 100%;
    }

    &-ColumnItem {
        margin-bottom: 8px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &-AddressList {
        margin-bottom: 30px;
    }

    &-Row {
        display: flex;
        align-items: center;
    }

    &-ColumnList {
        @include desktop {
            width: calc(50% - 10px);
        }

        &:nth-of-type(n + 3) {
            margin-top: 25px;
        }
    }

    &-ContactIcon {
        max-width: 20px;
        min-width: 15px;
        margin-right: 14px;
    }

    &-OpeningList li,
    &-ContactText {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: $grey-color-4;
    }

    &-AddressList li,
    &-OpeningList li {
        margin: 0 0 1px 0;
    }

    &-OpeningList {
        @include mobile {
            margin-bottom: 30px;
        }
    }

    &-Item_disabled {
        color: $grey-color-8;
    }
}
