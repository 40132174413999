/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

 :root {
    --hover-description-max-lines: 6;
    --animation-duration: 0.25s;
}

.HomepageCategories {
    max-width: var(--content-wrapper-width-special);
    width: 100%;
    padding: 0 32px;
    margin: 16px auto;

    grid-gap: 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-block-start: 3px;

    @include desktop {
        grid-template-columns: repeat(4, 1fr);
    }

    &-Figure {
        position: relative;
        background-color: $grey-color-10;
        @include mobile {
            margin-block-start: 24px;
        }

        @include desktop {
            margin-block-start: 5px;
            max-height: 285px;
        }

        .Image {
            width: 100%;
            margin: auto;
            display: block;
            height: 0;
            padding-block-end: 86%;

            &-Image {
                object-fit: cover;
                mix-blend-mode: multiply;
            }
        }

        @include desktop {
            &:hover {
                .HomepageCategories-VisibleOnHover {
                    visibility: visible;
                    opacity: 0.85;
                    width: inherit;
                }
            }
        }
    }

    &-Figcaption {
        --button-color: var(--color-white);
        --button-padding: 40px;
        --hollow-button-background: var(--color-white);
        --hollow-button-hover-background: var(--color-white);
        --hollow-button-hover-height: none;

        
        text-align: center;

        @include desktop {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            padding: 10px 16px;
            bottom: 45px;
            background: $hopmapage-figcaption;
        }

        &_isPlaceholder {
            padding-block-end: 31px;
        }

        @include mobile {
            width: 100%;
            margin-block-start: 24px;
            padding-inline: 16px;
            inset-block-end: 16px;
        }
    }
    &-Link {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .HomeSlider-Heading {
        color: $text-color-1;
        margin: 0;
        font-size: 18px;
        line-height: 25px;
        font-weight: 700;
    }

    &-VisibleOnHover {
        padding: 24px 16px;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: $grey-color-10;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        visibility: hidden;
        opacity: 0;
        transition: opacity var(--animation-duration), visibility var(--animation-duration), box-shadow var(--animation-duration);
        p {
            margin-bottom: 15px;
            line-height: 24px;
            text-align: center;
            color: $text-color-2;
            &:first-of-type {
                @include show-text-in-x-lines(var(--hover-description-max-lines));
            }
        }
        .HomeSlider-Heading {
            text-align: center;
            margin: 12px 0;
        }
    }
}
