.DeliveryAndPaymentInfo {
    ul {
        margin-bottom: 50px;

        li {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            
            &::before {
                display: none;
            }
    
            img {
                max-height: 40px;
                margin-left: 14px;
                width: auto;
            }
        }
    }
}
