@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
    src: local('Lato-Regular'), local('Lato-Regular'), url('/style/fonts/Lato-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local('Lato-Regular'), local('Lato-Regular'), url('/style/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-display: fallback;
    src: local('Ubuntu-Italic'), local('Ubuntu-Italic'), url('/style/fonts/Lato-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-display: fallback;
    src: local('Lato-SemiBold'), local('Lato-SemiBold'), url('/style/fonts/Lato-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 500;
    font-display: fallback;
    src: local('Lato-SemiBoldItalic'), local('Lato-SemiboldItalic'), url('/style/fonts/Lato-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local('Lato-Bold'), local('Lato-Bold'), url('/style/fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    font-display: fallback;
    src: local('Lato-BoldItalic'), local('Lato-BoldItalic'), url('/style/fonts/Lato-BoldItalic.ttf') format('truetype');
}
