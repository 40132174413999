.HomepageUsp {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 30px;
    background: $background-color-5;

    @include desktop {
        column-gap: 25px;
        row-gap: 0;
        padding: 25px 32px;
    }

    @include wide-desktop {
        column-gap: 90px;
    }

    &-Item {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: $usp-color;
        display: flex;
        align-items: center;

        @include mobile {
            justify-content: center;
            width: 50%;
        }

        img {
            margin-right: 10px;
            width: auto;
            height: auto;
            max-width: 84px;

            @include wide-desktop {
                margin-right: 20px;

            }
        }
    }
}