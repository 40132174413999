/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.contacts-wrapper {
    dt,
    dd {
        display: inline-block;
        font-size: 12px;

        @include mobile {
            font-size: 14px;
        }
    }

    dt {
        margin-inline-end: 12px;

        @include mobile {
            margin-inline-end: 14px;
        }
    }

    dd {
        margin-inline-end: 24px;

        @include mobile {
            margin-inline-end: 28px;
        }
    }
}
