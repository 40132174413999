/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --color-pagination-link-text: var(--imported_pagination_color, #{$black});
    --pagination-line-height: var(--imported_pagination_line_height, 20px);
    --pagination-font-size: var(--imported_pagination_font_size, 15px);
    --pagination-font-style: var(--imported_pagination_font_style, normal);
}

.PaginationLink {
    border: 1px solid transparent;
    border-radius: 2px;
    color: var(--color-pagination-link-text);
    font-size: var(--pagination-font-size);
    font-style: var(--pagination-font-style);
    display: block;
    line-height: var(--pagination-line-height);
    margin-inline-end: 6px;
    padding: 7px 13px;
    text-align: center;
    font-weight: 400;
    
    &:hover,
    &_isCurrent {
        color: $primary-color-1;
        text-decoration: none;
        border-color: $primary-color-1;
    }

    &_isArrow {
        padding: 7px 11px;

        &:hover {
            border-color:transparent;
        }
    }
}
