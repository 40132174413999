/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.AvailabilityModal {
    max-width: 550px;
    
    &-Message {
        text-align: justify;
    }
    
    &-Question {
        text-align: right;
        margin: 14px 0 0 0;
    }
    
    &-Product {
        display: grid;
        gap: 8px;
        grid-template-columns: 100px 1fr;
        padding: 8px 0;

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            display: none;
        }

        .AvailabilityStatus {
            &-Bar {
                height: 10px;

                @include mobile {
                    height: 8px;
                }
            }

            &-Qty {
                font-size: 14px;

                @include mobile {
                    font-size: 12px;
                }
            }
        }

        &Details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &Image {
            max-height: 100px;
            max-width: 100px;
        }

        &Name {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;

            @include mobile {
                font-size: 14px;
                line-height: 20px;
            }
        }

        &Price {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            text-align: right;

            @include mobile {
                font-size: 14px;
                line-height: 20px;
            }
        }
        
        &Quantity {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: $black;
            margin: 0;
        }
    }
    
    &-Line {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}