/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    // Colors
    --color-black: #{$black};
    --color-white: #{$white};
    --color-gray: #f3f3f2;
    --color-dark-gray: #CBCBCB;
    --color-karaka: #242424;
    --primary-divider-color: #e0e0e0;
    --secondary-error-color: #{$white};
    --primary-error-color: #{$form-error-color};
    --secondary-success-color: #{$white};
    --primary-success-color: #{$green-color-1};
    --secondary-info-color: var(--color-karaka);
    --primary-info-color: #{$yellow-color-1};
    --body-content-color: var(--color-black);
    --input-color: #131313;

    // Placeholders
    --placeholder-gradient-color: #FFF2EE;
    --placeholder-image: linear-gradient(to right, #ebebeb 0, var(--placeholder-gradient-color) 15%, #ebebeb 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderAnimation 2s linear infinite;

    --shake-animation: shake 820ms .5ms cubic-bezier(.36, .07, .19, .97) both;

    // Declare imported primary colors to change the theme's colors
    // "imported_..." variables are set up in Admin panel -> Stores -> Configuration -> Scandipwa -> Color Configuration
    // They have higher priority than "default_..." variables defined in code.
    --primary-base-color: var(--imported_primary_base_color, #{$default-primary-base-color});
    --primary-dark-color: var(--imported_primary_dark_color, #{$default-primary-dark-color});
    --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    --secondary-base-color: var(--imported_secondary_base_color, #{$default-secondary-base-color});
    --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-secondary-dark-color});
    --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});

    // max width
    --content-wrapper-width-special: 1422px;
    --newsletter-subscription-placeholder-color: #9CA3AF;

    //input 
    --imported_input_border_radius: 3px;
    --imported_input_padding: 12px 10px;
    --checkbox-border-color: #{$text-color-2};
}
