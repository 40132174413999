/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.NewsletterSubscription {
    .FieldForm {
        &-Fieldset {
            .Field {
                &-CheckboxLabel {
                    margin-top: 8px;
                    font-size: 12px;
                    margin: 20px 0 10px;

                    .input-control {
                        min-width: 18px;
                    }
                }
                input {
                    background-color: var(--newsletter-subscription-input-background);
                    border: 1px solid $crumb;
                    padding: 14px 12px;
                    font-size: 15px;

                    &::placeholder {
                        color: var(--newsletter-subscription-placeholder-color);
                    }
                }
            }
        }
    }
}
