/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ExpandableContent {
    border-top: 1px solid $crumb;

    &-Button {
        @include desktop {
            margin-top: 30px;
        }

        &_alwaysVisible:not(.ExpandableContent-Button_isContentExpanded) {
            + .ExpandableContent-Content {
                max-height: 0;
                overflow: hidden;
                opacity: 0;
            }
        }

        .AddIcon,
        .MinusIcon {
            fill: $brown-color-1;
        }
    }

    &-Content {
        display: none;

        @include desktop {
            margin-top: 30px;
        }

        &_isContentExpanded {
            display: block;
        }
    }
}
