$white: #ffffff;
$black: #5E5E5E;
$black-2: #1E1E1E;
$crumb: #E5E7EB;
$crumb-active: #E0640A;
$usp-color: #383838;

$primary-color-1: #E0640A;
$primary-color-2: #66310A;
$primary-color-3: #0056FF;
$primary-color-4: #0056FF;
$primary-color-5: #00BAFF;
$primary-color-6: #00BAFF;
$primary-color-7: #8CE6FF;
$primary-color-8: #FCF0E8;
$default-primary-base-color: $primary-color-1;
$default-primary-dark-color: $primary-color-2;
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-link-secondary-base-color: #66310A;
$default-link-secondary-dark-color: #8C1812;


$secondary-color-1: #E0640A;
$secondary-color-2: #030A1A;
$secondary-color-3: #051533;
$secondary-color-4: #081F4D;
$secondary-color-5: #0F3E99;
$secondary-color-6: #1453CC;
$secondary-color-7: #4C88FC;
$default-secondary-base-color: #eee;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);

$yellow-color-1: #FFD234;
$yellow-color-2: #FACC15;
$yellow-color-3: #FEF08A;
$green-color-1: #66BB6A;
$green-color-2: #DDFFDF;
$green-color-3: #bbf7d0;
$green-color-4: #f0fdf4;
$green-color-5: #14532d;
$green-color-6: #22C55E;
$brown-color-1: #803D0D;
$brown-color-2: #854D0E;
$orange-light-color: #FCF0E8;
$orange-light-color-2: #FAF6EB;
$orange-minicart-color: #FCDFCA;

$grey-color-1: $orange-light-color;
$grey-color-2: #989898;
$grey-color-3: $black;
$grey-color-4: #383838;
$grey-color-5: #575757;
$grey-color-6: #F0F0F0;
$grey-color-7: #9CA3AF;
$grey-color-8: #8C8C8C;
$grey-color-9: #9CA3AF;
$grey-color-10: #EBEBEB;
$grey-color-11: #D8DBDF;
$grey-color-12: #626262;
$grey-color-13: #4B5563;
$grey-color-14: #F3F4F6;
$grey-color-15: #E5E7EB;

$background-color-1: $white;
$background-color-2: #F9FAFB;
$background-color-3: #7E858F;
$background-color-4: #ffffff80;
$background-color-5: #F3F4F6;
$background-color-6: #51595D;

$disabled-color: $grey-color-9;
$form-error-color: #DC2626;
$warning-color: #FAB100;
$red-color-1: #e67676;
$discount-price-color: #A61C15;
$discount-background-color: #FAE2E1;

$text-color-1: #131313;
$text-color-2: #383838;
$text-color-3: #5E5E5E;
$text-color-4: $text-color-1;

$box-shadow: rgba(0, 0, 0, .15);

$primary-gradient: linear-gradient(90deg, $secondary-color-1 57.54%, $secondary-color-1 78.33%, $secondary-color-1 100.85%);

$font-lato: 'Lato', sans-serif;
$font-muli: $font-lato;
$font-standard-size: 62.5%;

$hopmapage-figcaption: rgba(255, 234, 209, .6);
