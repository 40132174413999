.AboutUsInfo {
    margin-bottom: 170px;

    &-Content {
        z-index: 2;
        margin-bottom: 94px;

        @include wide-desktop {
            max-width: 785px;
        }

        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 29px;
            color: $text-color-1;
            margin: 0;
        }

        .Button {
            margin-top: 30px;
        }
    }

    &-Image {
        position: absolute;
        top: -9999em;

        @include wide-desktop {
            top: -20px;
            right: 0;
        }
    }
}
