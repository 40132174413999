@mixin grey-page-bg {
    @include mobile {
        background: #fff;
    }

    &::before {
        z-index: -1;
        content: '';
        position: fixed;
        inset-inline-start: 0;
        inset-inline-end: 0;
        inset-block-start: 0;
        inset-block-end: 0;
        background: var(--secondary-base-color);

        @include desktop {
            content: none;
        }
    }
}

@mixin default-list {
    ul,
    ol {
        margin-inline-start: 20px;

        li {
            &::before {
                inset-inline-start: -20px;
            }
        }
    }
}

@mixin description-tags {
    li,
    span,
    div,
    p,
    dt,
    dd,
    dl {
        @content
    }
}

@mixin mobile-bottom-wrapper {
    @include mobile {
        @content;

        border-block-start: 1px solid var(--primary-divider-color);
        position: fixed;
        inset-inline-start: 0;
        width: 100%;
        z-index: 85;
        background-color: var(--color-white);
        transition: transform 200ms cubic-bezier(.47, 0, .745, .715);
    }
}

@mixin show-text-in-x-lines($lines: 2) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    overflow: hidden;
    white-space: initial;
    text-overflow: initial;
}

@mixin truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
