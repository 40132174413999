/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.RadioButtonIcon {
    background-color: #ffffff;
    border: 2px solid var(--checkbox-border-color);
    cursor: pointer;
    display: inline-block;
    height: 18px;
    width: 18px;
    position: relative;
    padding: 0;
    transition-duration: .15s;
    transition-property: background-color, border-color;
    transition-timing-function: ease-in;
    will-change: background-color, border-color;
    border-radius: 50%;

    &:after {
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        content: '';
        height: 10px;
        pointer-events: none;
        position: absolute;
        top: 3px;
        transition-duration: .25s;
        transition-property: background-color;
        transition-timing-function: ease-in;
        width: 12px;
        will-change: background-color;
    }

    &:hover, &_isActive {
        border-color:  $primary-color-1;
        background-color: white;

        &:after {
            opacity: 1;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color:  $primary-color-1;
            background-image: none;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
