/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.StockStatus {
    &-InStock,
    &-OutOfStock,
    &-Preorder {
        font-size: 15px;
        color: $text-color-2;
        line-height: 24px;
        display: flex;
        
        &::before {
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 20px;
            margin-right: 8px;
            display: inline-block;
        }
    }

    &-InStock {
        &::before {
            background: $green-color-6;
            border: 8px solid $green-color-2;
        }
    }

    &-OutOfStock {
        &::before {
            background: $form-error-color;
            border: 8px solid $red-color-1;
        }
    }
    
    &-Preorder {
        &::before {
            background: $warning-color;
            border: 8px solid $orange-light-color-2;
        }
    }
}