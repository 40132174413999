[dir="ltr"] .LandingPage {
    &.CornLP {
        @include desktop {
            margin-top: -24px;
        }

        @include mobile {
            margin-left: -14px;
            margin-right: -14px;

            & > div:not(.LandingPage-FullWidthBoxMobile) {
                margin-left: 14px;
                margin-right: 14px;
                width: 100%;
            }
        }

        .LandingPage-FullWidthBox {
            @include desktop {
                display: block;
            }

            @include mobile {
                display: none;
            }

            .LandingPage-Box {
                h1 {
                    color: #F9FAFB;
                    font-size: 54px;
                    line-height: 64px;
                    font-weight: 700;
                    max-width: 670px;
                    margin-bottom: 25px;
                }

                .LandingPage-Description {
                    color: #ffffff;
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 25px;
                }
            }
        }

        .LandingPage-FullWidthBoxMobile {
            @include desktop {
                display: none;
            }

            @include mobile {
                display: block;
                width: calc(100% + 28px);

                .LandingPage-FullWidthBoxWrapper {
                    padding: 100px 32px 20px;
                }
            }

            .LandingPage-Box {
                h1 {
                    color: #F9FAFB;
                    font-size: 26px;
                    line-height: 33px;
                    font-weight: 700;
                    max-width: 670px;
                    margin-bottom: 25px;
                }

                .LandingPage-Description {
                    color: #ffffff;
                    font-size: 12px;
                    line-height: 19px;
                    margin-bottom: 25px;
                }

                .LandingPage-Button {
                    width: 46%;
                    margin-top: 0;
                    font-size: 12px;
                }

                .LandingPage-Buttons {
                    .RenderWhenVisible {
                        display: flex;
                        flex-direction: row;
                    }
                }
            }
        }

        .LandingPage-Counters {
            margin-top: 50px;
            margin-bottom: 50px;

            @include mobile {
                width: 100%;
                padding: 0;
                margin: 35px auto;
                scroll-snap-type: x mandatory;
                scroll-behavior: smooth;
                overflow-x: auto;
                display: flex;
                flex-direction: row;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        .LandingPage-CountersElement {
            color: #9CA3AF;
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            & > div {
                color: #9CA3AF;
            }

            @include mobile {
                scroll-snap-align: start;
                min-width: 45%;
            }
        }

        .LandingPage-Type {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            margin-bottom: 50px;

            @include mobile {
                scroll-snap-type: x mandatory;
                scroll-behavior: smooth;
                overflow-x: auto;
                display: flex;
                flex-direction: row;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            &__item {
                @include mobile {
                    min-width: 80%;
                    scroll-snap-align: start;
                    margin-right: 20px;
                    background: #F9FAFB;
                    padding: 10px;
                    border-radius: 12px;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }

            &__item-header {
                @include desktop {
                    display: flex;
                    align-items: center;
                    font-size: 54px;
                    line-height: 64px;
                    justify-content: center;

                    & > img {
                        max-width: 96px;
                        width: 100%;
                        height: auto;
                        margin-right: 15px;
                        margin-bottom: 10px;
                    }
                }

                @include mobile {
                    display: flex;
                    flex-direction: column;
                    font-size: 31px;
                    line-height: 43px;
                    justify-content: left;

                    & > img {
                        max-width: 80px;
                        width: 100%;
                        height: auto;
                        margin-right: 15px;
                        margin-bottom: 10px;
                    }
                }
            }

            &__item-contnet {
                @include desktop {
                    margin: 0 45px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;

                    .item-description {
                        margin: 0;
                    }

                    .dot {
                        width: 12px;
                        height: 12px;
                        background: #E5E7EB;
                        border-radius: 6px;
                        margin: 0 10px;
                    }
                }

                @include mobile {
                    display: flex;
                    flex-direction: column;
                    color: #383838;
                    font-size: 15px;
                    line-height: 21px;
                }
            }
        }

        .LandingPage {
            &-ProductCollapsible {
                .ExpandableContent {
                    border-top: none;

                    &-Button {
                        background: transparent;
                        margin-top: 20px;
                        padding: 20px 0;
                    }

                    &-Heading {
                        color: #131313;
                        font-weight: 700;
                        background: #ffffff;
                        padding: 5px 40px 5px 0;
                        padding: 5px 40px 5px 0;
                        z-index: 2;

                        @include desktop {
                            font-size: 18px;
                        }

                        @include mobile {
                            font-size: 15px;
                        }
                    }

                    .AddIcon,
                    .MinusIcon {
                        background: #E5E7EB;
                        fill: #131313;
                        z-index: 2;

                        @include desktop {
                            padding: 5px;
                            border-radius: 16px;
                        }

                        @include mobile {
                            padding: 5px;
                            border-radius: 20px;
                            width: 36px;
                            height: 36px;
                        }
                    }

                    &::after {
                        content: '';
                        border-top: 2px solid #E5E7EB;
                        height: 2px;
                        display: block;
                        position: absolute;
                        left: 6%;
                        top: 35px;
                        z-index: 1;

                        @include desktop {
                            width: 88%;
                        }

                        @include mobile {
                            width: 84%;
                        }
                    }

                    @include mobile {
                        &:last-of-type {
                            border-bottom: none;
                        }
                    }

                    .CmsBlock-Wrapper {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-bottom: 40px;

                        @include mobile {
                            scroll-snap-type: x mandatory;
                            scroll-behavior: smooth;
                            overflow-x: auto;
                            display: flex;
                            flex-direction: row;
                            -ms-overflow-style: none;
                            scrollbar-width: none;

                            &::-webkit-scrollbar {
                                display: none;
                            }
                        }

                        .item-element {
                            @include desktop {
                                padding: 0 10px;
                                width: 33.33%;
                                box-sizing: border-box;

                                &:first-of-type {
                                    padding-left: 0;
                                }

                                &:last-of-type {
                                    padding-right: 0;
                                }
                            }

                            @include mobile {
                                scroll-snap-align: start;
                                padding: 0 10px;
                                min-width: 80%;
                                box-sizing: border-box;

                                &:first-of-type {
                                    padding-left: 0;
                                }

                                &:last-of-type {
                                    padding-right: 0;
                                }
                            }

                            ul {
                                display: flex;
                                flex-direction: column;
                            }

                            li {
                                font-size: 15px;
                                line-height: 21px;
                                color: #383838;
                                display: flex;

                                &::before {
                                    content: '';
                                    padding: 0;
                                    margin-left: 0;
                                    margin-right: 0;
                                }
                            }

                            .checked-green {
                                width: 28px;
                                background: transparent url("./icons/checkmark-green.svg") no-repeat center center;
                                display: block;
                            }

                            .checked-orange {
                                width: 28px;
                                background: transparent url("./icons/checkmark-orange.svg") no-repeat center center;
                                display: block;
                            }

                            .checked-blue {
                                width: 28px;
                                background: transparent url("./icons/checkmark-blue.svg") no-repeat center center;
                                display: block;
                            }

                            .item-text {
                                width: calc(100% - 30px);
                                display: inline-block;
                            }


                        }

                        .item-table {
                            width: 100%;
                            display: flex;
                            flex-direction: column;

                            .row {
                                padding: 10px;

                                &:nth-child(even) {
                                    background: #F9FAFB;
                                }

                                &:nth-child(odd) {
                                    background: #F3F4F6;
                                }

                                & > span {
                                    display: inline-block;
                                    width: 50%;
                                    font-size: 15px;
                                    color: #5E5E5E;
                                    line-height: 21px;
                                }
                            }
                        }
                    }
                }
            }

            &-Tabs {
                & > ul {
                    margin: 0;
                    flex: 1;

                    @include mobile {
                        scroll-snap-type: x mandatory;
                        scroll-behavior: smooth;
                        overflow-x: auto;
                        display: flex;
                        flex-direction: row;
                        -ms-overflow-style: none;
                        scrollbar-width: none;

                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }

                    li {
                        @include mobile {
                            scroll-snap-align: start;
                        }

                        &.tab-element {
                            flex: 1 0;
                            min-width: unset;

                            @include mobile {
                                min-width: unset;
                            }
                        }

                        img {
                            filter: grayscale(1);
                        }

                        &.active {
                            img {
                                filter: grayscale(0);
                            }
                        }
                    }
                }

                &Wrapper {
                    .active {
                        flex-direction: column;
                    }

                    h5 {
                        @include desktop {
                            font-size: 22px;
                        }

                        @include mobile {
                            font-size: 15px;
                        }
                    }

                    .row {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 0 0 30px;

                        &:last-of-type {
                            margin: 0;
                        }

                        & > div {
                            & > ul {
                                display: flex;
                                flex-direction: row;
                                list-style-type: none;

                                li {
                                    margin: 0 8px;
                                    display: flex;
                                    align-items: center;
                                    color: #5E5E5E;
                                    font-weight: 700;

                                    @include desktop {
                                        font-size: 15px;
                                    }

                                    @include mobile {
                                        font-size: 12px;
                                    }

                                    &::before {
                                        content: '';
                                        margin-left: 0;
                                        margin-right: 0;
                                        padding: 0;
                                    }

                                    & > span {
                                        display: inline-block;
                                        width: 18px;
                                        height: 18px;
                                        margin-right: 8px;
                                        border-radius: 4px;

                                        &.color-orange {
                                            background-color: #F28230;
                                        }

                                        &.color-gray {
                                            background-color: #9CA3AF;
                                        }

                                        &.color-gray-dark {
                                            background-color: #7E858F;
                                        }
                                    }
                                }
                            }
                        }

                        & > ul {
                            display: flex;
                            width: 100%;
                            margin-top: 25px;

                            @include mobile {
                                scroll-snap-type: x mandatory;
                                scroll-behavior: smooth;
                                overflow-x: auto;
                                display: flex;
                                flex-direction: row;
                                -ms-overflow-style: none;
                                scrollbar-width: none;

                                &::-webkit-scrollbar {
                                    display: none;
                                }
                            }

                            li {
                                @include desktop {
                                    width: 33.33%;
                                }

                                @include mobile {
                                    min-width: 80%;
                                    scroll-snap-align: start;
                                }

                                box-sizing: border-box;
                                overflow: hidden;
                                display: flex;
                                flex-direction: column;
                                margin: 0 10px;
                                align-items: center;
                                justify-content: center;
                                color: #131313;

                                @include desktop {
                                    font-size: 15px;
                                    font-weight: 700;
                                }

                                @include mobile {
                                    font-size: 12px;
                                    font-weight: 400;
                                }

                                img {
                                    margin-bottom: 10px;
                                }

                                &:first-of-type {
                                    margin-left: 0;
                                }

                                &:last-of-type {
                                    margin-right: 0;
                                }

                                &::before {
                                    content: '';
                                }
                            }

                        }
                    }
                }
            }

            &-ProductImage {
                @include mobile {
                    margin-right: 0;

                    img {
                        border-radius: 8px;
                    }
                }
            }

            &-ProductActions {
                &Wrapper {
                    @include desktop {
                        width: 100%;
                    }

                    @include mobile {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;

                        h3 {
                            width: 100%;
                        }
                    }
                }

                .ProductConfigurableAttributes {
                    padding-bottom: 0;
                    margin-bottom: 20px;
                }
            }

            &-AddProduct-Fixed {
                display: flex;
                align-items: center;

                @include mobile {
                    display: none;
                }

                .ButtonsBlock {
                    display: none;
                }

                .ProductConfigurableAttributes {
                    margin-bottom: 0;
                }

                .Field-Wrapper_type_numberWithControls {
                    margin-bottom: 0;
                }

                .LandingPage-Product {
                    align-items: center;
                }
            }

            &-ProductAddToCart {
                flex: 1;
            }

            &-BoxesSpecial {
                display: block;
                overflow: auto;

                .ProductList.ProductListWidget {
                    .slick-next {
                        right: 0;
                    }

                    h2 {
                        text-align: center;
                        font-weight: 700;

                        @include desktop {
                            font-size: 45px;
                        }
                    }
                }
            }

            &-Product {
                align-items: flex-start;
            }
        }

        .ProductActions {
            &-AttributesWrapper {
                @include mobile {
                    width: 100%;
                }
            }

            &-AddToCartWrapper {
                align-items: flex-start;

                @include mobile {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    flex-wrap: nowrap;
                    width: 100%;

                    .Field-Wrapper {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .ButtonsBlock {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            margin-bottom: 20px;
        }

        .LpButton {
            padding: 8px 20px;
            border-radius: 3px;
            border: 2px solid #5E5E5E;
            color: #5E5E5E;
            font-size: 18px;
            font-weight: 700;
            cursor: pointer;

            &:hover {
                background: #FCF0E8;
                border-color: #FA700B;
            }

            &_isActive {
                border-color: #FA700B;
                background: #FCF0E8;
                color: #131313;
                position: relative;
                cursor: default;

                &::after {
                    content: '';
                    position: absolute;
                    background: url('./icons/check-circle.svg') no-repeat 0 0;
                    width: 24px;
                    height: 24px;
                    top: -12px;
                    right: -12px;
                }
            }
        }

        .ProductWidget {
            &-Title {
                flex: 1;
            }
        }
    }
}
