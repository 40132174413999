/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
.SearchField {
    @include desktop {
        width: 70%;
    }

    @include wide-desktop {
        width: 100%;
        max-width: 592px;
        grid-column: 1 / 3;
        margin-left: calc(var(--header-logo-width) + 204px);
    }

    input {
        border: none;
        border-bottom: 1px solid $grey-color-7;
        border-radius: 0;
        padding: 12px 14px;
        min-height: 44px;
        height: 44px;

        &,
        &::placeholder {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: $black;
        }

        &::placeholder {
            color: $grey-color-7;
        }
    }

    &-CloseIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        &:hover {
            cursor: pointer;
        }
    }

    .SearchIcon {
        fill: $brown-color-1;
        pointer-events: none;
    }
    
}
