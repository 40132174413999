:root {
    --default-button-padding: 20px;
    --default-button-radius: 3px;
    --default-button-height: 48px;
    --default-text-decoration: none;
    --button-border-width: 21px;
    // Primary button
    --button-background: var(--imported_buttons_background_color, var(--primary-base-color));
    --button-border: var(--imported_buttons_border_color, var(--primary-base-color));
    --button-border-radius: var(--imported_buttons_border_radius, var(--default-button-radius));
    --button-color: var(--imported_buttons_color, #{$white});
    --button-height: var(--imported_buttons_height, var(--default-button-height));
    --button-padding: var(--imported_buttons_padding, var(--default-button-padding));
    --button-text-decoration: var(--imported_buttons_text_decoration, var(--default-text-decoration));
    // Primary button (Hover)
    --button-hover-background: var(--imported_hoverButtons_background_color, var(--primary-dark-color));
    --button-hover-border: var(--imported_hoverButtons_border_color, var(--primary-dark-color));
    --button-hover-border-radius: var(--imported_hoverButtons_border_radius, var(--default-button-radius));
    --button-hover-color: var(--imported_hoverButtons_color, #{$white});
    --button-hover-height: var(--imported_hoverButtons_height, var(--default-button-height));
    --button-hover-padding: var(--imported_hoverButtons_padding, var(--default-button-padding));
    --button-hover-decoration: var(--imported_hoverButtons_text_decoration, var(--default-text-decoration));
    // Secondary button (Hollow)
    --hollow-button-background: var(--imported_hollowButtons_background_color, tranparent);
    --hollow-button-border: var(--imported_hollowButtons_border_color, #{$primary-color-2});
    --hollow-button-border-radius: var(--imported_hollowButtons_border_radius, var(--default-button-radius));
    --hollow-button-color: var(--imported_hollowButtons_color, #{$primary-color-2});
    --hollow-button-height: var(--imported_hollowButtons_height, var(--default-button-height));
    --hollow-button-padding: var(--imported_hollowButtons_padding, var(--default-button-padding));
    --hollow-button-text-decoration: var(--imported_hollowButtons_text_decoration, var(--default-text-decoration));
    // Secondary button (Hover)
    --hollow-button-hover-background: var(--imported_hollowHoverButtons_background_color, tranparent);
    --hollow-button-hover-border: var(--imported_hollowHoverButtons_border_color, #{$primary-color-1});
    --hollow-button-hover-border-radius: var(--imported_hollowHoverButtons_border_radius, var(--default-button-radius));
    --hollow-button-hover-color: var(--imported_hollowHoverButtons_color, #{$primary-color-1});
    --hollow-button-hover-height: var(--imported_hollowHoverButtons_height, var(--default-button-height));
    --hollow-button-hover-padding: var(--imported_hollowHoverButtons_padding, var(--default-button-padding));
    --hollow-button-hover-text-decoration: var(--imported_hollowHoverButtons_text_decoration, var(--default-text-decoration));

    // LikeLink button (Hollow)
    --like-link-button-background: tranparent;
    --like-link-button-border: #{$default-link-secondary-base-color};
    --like-link-button-color: #{$default-link-secondary-base-color};

    // LikeLink button (Hover)
    --like-link-button-hover-background: tranparent;
    --like-link-button-hover-border: #{$default-link-secondary-dark-color};
    --like-link-button-hover-color: #{$default-link-secondary-dark-color};

    // LikeSecondaryLink button (Hollow)
    --like-secondary-link-button-background: tranparent;
    --like-secondary-link-button-border: #{$default-link-secondary-base-color};
    --like-secondary-link-button-color: #{$default-link-secondary-base-color};

    // LikeLink LikeSecondaryLink (Hover)
    --like-secondary-link-button-hover-background: tranparent;
    --like-secondary-link-button-hover-border: #{$default-link-secondary-dark-color};
    --like-secondary-link-button-hover-color: #{$default-link-secondary-dark-color};
}

.Button {
    @include button;
}

button {
    background-color: transparent;

    // override default blue color for lists in Safari since iOS15

    & * {
        color: var(--color-black);
    }
}
