/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.homepage-slider {
    @include desktop {
        height: 440px;
        display: flex;
        flex-direction: column;
    }
    .HomeSlider {
        &-Heading {
            color: $text-color-1;
            font-size: 54px;
            font-weight: 500;
            margin-block-end: 30px;

            @include mobile {
                font-size: 30px;
                line-height: 1.2em;
                margin-block-end: 16px;
            }
        }

        &-Subheading {
            color: $text-color-1;
            margin-block-end: 37px;
            @include mobile {
                margin-block-end: 16px;
            }
        }
    }

    .SliderWidget {
        &-Figcaption {
            background-color: $background-color-4;

            @include desktop {
                inset-inline-start: 10%;
                max-width: 450px;
                padding: 10px 20px;
            }
    
            @include mobile {
                padding-inline: 16px;
                text-align: center;
                .Button {
                    width: auto;
                    height: auto;
                    padding: 6px 20px;
                    font-size: 12px;
                }
            }
        }
    }
}
