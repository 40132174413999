/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.AttributesDetailsTable {
    &:not(:empty) {
        margin: 5px 0;
        font-size: 15px;
        line-height: 24px;
        min-width: 50%;
    }

    @include mobile {
        width: 100%;
    }

    tr {
        td {
            color: $text-color-1;

            &:first-of-type {
                padding-right: 24px;
                color: $text-color-3;
            }
        }

        &:nth-child(even) {
            background: $grey-color-6;
            border-radius: 2px;
        }
    }
}
