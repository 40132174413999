/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --header-logo-width: 145px;
    --header-logo-height: 52px;
}

[dir="ltr"] .Header {
    border-bottom: 0;
    box-shadow: 0 2px 18px 0 $box-shadow;

    &-TopMenu {
        background: $background-color-2;
        max-width: 100%;
        width: 100%;
    }

    &-News {
        box-sizing: border-box;
        display: flex;
        justify-content: right;
        margin: 0 auto;
        max-width: var(--content-wrapper-width-special);
        padding-left: 32px;
        padding-right: 32px;
        width: 100%;
    }

    &-Welcome {
        margin-right: 10px;
    }

    &-MinicartItemCount {
        height: 19px;
        min-width: 19px;
        font-size: 12px;
        right: -10px;
        top: -7px;
    }

    &-Title {
        @include mobile {
            &_isVisible {
                align-items: center;
                display: flex;
                font-size: 14px;
                justify-content: center;
                line-height: 18px;
                white-space: inherit;
            }
        }
    }

    &-Button_type_minicart,
    &-CompareButtonWrapper {
        margin-left: 30px;

        @include mobile {
            margin-left: 0;
        }
    }

    &-CompareButtonWrapper {
        .CompareIcon {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-Nav {
        margin-top: 10px;
    }

    &-LogoWrapper {
        img {
            max-width: 145px;
        }
    }

    &-CompareButtonWrapper {
        order: 1;
        margin-left: auto;
    }

    &-MyAccountContainer {
        order: 2;
        margin-left: 30px;
    }

    &-Button_type_minicart {
        order: 3;
    }

    .UserIcon,
    .CompareIcon,
    .CartIcon {
        path {
            stroke: $brown-color-1;
        }
    }

    &_isCheckout {
        @include desktop {
            box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.08);
        }

        .Header-MyAccountContainer {
            @include desktop {
                margin-left: auto;
            }
        }

        .Header-Nav {
            @include desktop {
                margin-bottom: 9px;
            }
        }
    }

    &-CompareCount {
        height: 19px;
        min-width: 19px;
        font-size: 12px;
        right: -10px;
        top: -7px;
        background: var(--primary-base-color);
    }
}
