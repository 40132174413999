/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .ResetAttributes {
    @include desktop {
        margin-top: 5px;
    }

    &-Title {
        font-size: 18px;
        font-weight: 700;
    }

    &-CloseIcon {
        display: flex;
        align-items: center;

        .CloseIcon {
            height: 20px;
            width: 20px;
            
            path {
                stroke: $grey-color-13;
            }
        }
    }

    &-AttributeLabel,
    &-AttributeOption {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: $text-color-3;
    }

    &-AttributeOption {
        color: $text-color-1;
    }
}
