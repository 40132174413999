/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

$select-width: 160px !default;
$select-height: 42px !default;
$select-option-height: 40px !default;
$select-arrow-height: 16px !default;
$select-arrow-width: 16px !default;

.FieldSelect {
    &-Clickable {
        cursor: pointer;
        display: flex;
        align-items: center;

        #storeSwitcher,
        #CurrencySwitcherList {
            line-height: 20px;

            @include mobile {
                border-bottom: 1px solid var(--primary-divider-color);
            }
        }
    }

    .ChevronIcon {
        display: inline-block;
        width: $select-arrow-width;
        height: $select-arrow-height;
        pointer-events: none;
        position: absolute;
        inset-inline-end: 12px;
        right: 16px;
    }

    &_isExpanded {
        &::after {
            transform: rotate(-90deg);
        }

        select {
            border-image-source: $primary-gradient;
            border-image-slice: 1;
        }

        .FieldSelect-Clickable {
            @include desktop {
                &::after {
                    content: '';
                    display: block;
                    height: 1px;
                    width: calc(100% - 16px);
                    position: absolute;
                    bottom: 1px;
                    left: 8px;
                    background-color: $secondary-color-1;
                }
            }
        }
    }

    &-Select {
        width: 100%;
        min-height: 48px;
        padding: 11px 12px;
        padding-inline-end: 40px;
        height: max-content;
        font-size: 15px;
        color: $text-color-1;

        @include desktop {
            pointer-events: none;
        }

        &_isExpanded {
            border-color: $primary-gradient;
            opacity: 1;

            .ChevronIcon path {
                stroke: $secondary-color-1;
            }
        }

        &_isDisabled {
            opacity: 0.6;
        }
    }

    &-Options {
        position: absolute;
        width: 100%;
        inset-block-start: 100%;
        inset-inline-start: 0;
        z-index: 40;
        overflow-y: auto;
        will-change: max-height;
        max-height: 0;
        -webkit-overflow-scrolling: touch;
        min-width: 100%;
        display: none;
        background: $white;
        border-radius: 2px;

        &Wrapper {
            @include desktop {
                display: none;

                &_isExpanded {
                    display: block;
                    overflow-y: auto;
                    max-height: $select-height * 4.65;

                    &::-webkit-scrollbar {
                        width: 4px;
                        background-color: $disabled-color;
                    }

                    &::-webkit-scrollbar-track {
                        width: 4px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $secondary-color-1;
                        outline: none;
                    }
                }
            }
        }

        @include mobile {
            height: 0;
        }

        &_isExpanded {
            display: block;

            @include desktop {
                z-index: 15;
                max-height: fit-content;
                border: 1px solid $secondary-color-1;
                border-top: 0;
                background-color: $white;
                bottom: 1px;
                position: relative;
            }

            .FieldSelect-Option:first-of-type {
                @include desktop {
                    margin-top: 12px;
                }
            }
        }

        &_isUpDirection {
            inset-block-start: auto;
            inset-block-end: 100%;
            border-width: 1px 1px 0;
        }

        &_isNotScrollable {
            overflow-y: hidden;

            // Firefox support
            /* stylelint-disable-next-line declaration-no-important */
            scrollbar-width: none !important;
            /* stylelint-disable-next-line declaration-no-important */
            scrollbar-color: none !important;

            &::-webkit-scrollbar {
                display: none;
            }

            &::-webkit-scrollbar-thumb {
                display: none;
            }
        }
    }

    &-Option {
        cursor: pointer;
        z-index: 1;
        will-change: max-height;
        min-height: $select-option-height;
        background: $white;
        padding: 8px 10px;
        margin: 0 8px;
        font-size: 18px;
        line-height: 24px;

        @include mobile {
            line-height: 42px;
            padding-inline-start: 21px;
        }

        &:last-of-type {
            margin: 0 8px 14px 8px;
        }

        &_isPlaceholder {
            color: $secondary-color-1;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            opacity: 1;
        }

        &::before {
            content: none;
        }

        &:hover,
        &:focus {
            @include desktop {
                background: $orange-light-color;
                border-radius: 4px;

                &::after {
                    content: '';
                    background-image: url('../../style/icons/checkmark2.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    display: block;
                    width: 15px;
                    height: 12px;
                    position: absolute;
                    top: 50%;
                    right: 7px;
                    transform: translateY(-50%);
                }
            }
        }

        &_isHovered {
            @include desktop {
                background: $grey-color-1;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: 0.4;

            >span,
            .CustomOption-Label,
            .CustomOption-Price {
                opacity: 0.4;
            }

            @include mobile {
                border-top: 1px solid var(--input-border-color-focus);
            }
        }
    }
}
