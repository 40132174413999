.AboutUsRecommendation {
    background: $grey-color-6;
    padding: 35px 0;
    margin: 0 -14px;

    @include desktop {
        margin: 0 -32px;
    }

    @include wide-desktop {
        margin: 0 -284px;
    }
    
    &-Image {
        position: absolute;
        bottom: -9999em;
        z-index: 2;

        @include wide-desktop {
            bottom: -155px;
            left: -440px;
        }
    }

    &-Wrapper {
        background: $grey-color-6;

        @include wide-desktop {
            max-width: 900px;
            margin: 0 auto;
        }
    }

    &-Content {   
        text-align: center;

        span {
            color: $secondary-color-5;
        }
    
        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: $grey-color-4;
            margin: 0;
        }
    }

    &-Heading {
        margin: 16px 0;
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        color: $grey-color-4;
        padding: 0 20px;
    }
}

.CmsPage {
    .AboutUsRecommendation-Heading {
        @include mobile {
            font-size: 22px;
        }
    }
}
