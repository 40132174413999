.RecentlyViewedWidget {
    max-width: var(--content-wrapper-width-special);
    width: 100%;
    padding: 0 32px;
    margin: 50px auto 0;
        
    h2 {
        font-size: 31px;
        margin-top: 0;
    }
}