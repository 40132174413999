/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.HomePage {
    .CmsPage {
        &-Heading {
            display: none;
        }
        &,
        &-Wrapper {
            margin-block-end: 0;
            margin-block-start: 0;
        }

        &-Wrapper {
            @include desktop {
                max-width: 100%;
                padding: 0;
                margin: 0;
                
                .CmsPage-Content {
                    > div {
                        &:not(:has(.full-width)) {
                            max-width: var(--content-wrapper-width-special);
                            padding: 0 32px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }

        &-Content {
            @include mobile {
                overflow-x: unset;
            }
        }
    }

    &-Background {
        background: $background-color-2;

        &.no-top-margin {
            .ProductList.ProductListWidget {
                margin-top: 0;
            }
        }
    }
}
