/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Menu {
    &-MenuWrapper {
        padding-left: 32px;
        padding-right: 32px;
    }

    &-MainCategories {
        margin: 0;
        width: 100%;
    }

    &-ItemList {
        @include desktop {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }
    }

    &-Item {
        @include desktop {
            min-height: var(--menu-item-height);
            height: auto;
        }

        &:last-child {
            margin: 0;
        }

        &Caption {
            &_type {
                &_main {
                    color: $text-color-1;
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 21px;
                    text-transform: none;

                    @include desktop {
                        margin: 0 0 17px;
                    }
                }
            }

            &:hover {
                color: $brown-color-1;
            }
        }
    }

    &-SubLevelDesktop {
        display: none;
    }
}
