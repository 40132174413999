.ContactBar {
    width: 100%;
    display: flex;

    p {
        font-size: 12px;
        &,
        a {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
        }

        a {
            margin-left: 4px;
        }
        
        + p {
            margin-left: 30px;
        }
    }
}