@charset "UTF-8";

/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    transition: opacity ease-in-out .15s;
    z-index: 1;

    &::before {
        font-size: 20px;
        line-height: 1;
        display: block;
        content: "";
        width: 38px;
        height: 38px;
        background-color: $white;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid #66310A;
        border-radius: 4px;
    }
}

.slick-prev {
    left: 10px;

    @include desktop {
        left: 0;
    }

    &.slick-disabled {
        opacity: 0;
        pointer-events: none;
    }

    &::before {
        background-image: url('../icons/arrow-left-slider.svg');
    }

    &:hover,
    &:focus {
        color: transparent;
        outline: none;
        background: transparent;
    }

    &:hover::before {
        opacity: 1;
    }

    &:focus::before {
        opacity: 1;
    }
}

.slick-next {
    right: 10px;

    @include desktop {
        right: 0;
    }

    &.slick-disabled {
        opacity: 0;
        pointer-events: none;
    }

    &:before {
        background-image: url('../icons/arrow-right-slider.svg');
    }

    &:hover,
    &:focus {
        color: transparent;
        outline: none;
        background: transparent;
    }
}


/* Dots */
.slick-dotted {
    &.slick-slider {
        margin-bottom: 30px;
    }
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        &.slick-active {
            button {
                &::before {
                    opacity: .75;
                    color: $black;
                }
            }
        }

        button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 20px;
            height: 20px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;

            &:hover,
            &:focus {
                outline: none;

                &:before {
                    opacity: 1;
                }
            }

            &::before {
                font-size: 6px;
                line-height: 20px;
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                content: '•';
                text-align: center;
                opacity: .25;
                color: $black;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
    }
}
