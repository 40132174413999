[dir="ltr"] .BlogPostCard {
    &-Details {
        a {
            font-weight: 400;
        }
    }
}

.PostsDetails-Content {
    ul {
        li {
            padding-left: 35px;
        }
    }
}
