/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Accordion {
    max-width: 900px;

    &-Header {
        color: $black;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        margin: 0;
        cursor: pointer;
        padding: 0 20px 0 54px;

        @include desktop {
            max-width: 765px;
            padding: 0 6px 0 54px;
        }
    }

    &-Number {
        width: 36px;
        height: 36px;
        padding: 6px 12px;
        color: $white;
        background: $secondary-color-5;
        border-radius: 66px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
    }

    &-Box {
        margin-bottom: 20px;

        .ExpandableContent-Button {
            cursor: pointer;
            padding: 20px;
            background: $grey-color-1;
            border-radius: 2px;

            .AddIcon,
            .MinusIcon {
                min-width: 28px;
                width: 28px;
                height: 28px;
            }
        }
    }

    &-Content {
        p {
            font-weight: 300;
            font-size: 18px;
            line-height: 32px;
            color: $grey-color-4;
            margin: 0;
        }
    }
}
