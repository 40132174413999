/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --default-input-padding: 15px 20px 14px;
    --input-background-color: var(--imported_input_background_color, #{$white});
    --input-background-color-focus: #{$white};
    --input-border-color: #{$grey-color-7};
    --input-border-color-focus: #{$secondary-color-1};
    --input-border-radius: var(--imported_input_border_radius, 2px);
    --input-padding: var(--imported_input_padding, var(--default-input-padding));
    --input-placeholder-color: #{$grey-color-7}; 
    --input-color: #{$text-color-1};
    --input-type-number-width: 28px;
}

input,
textarea,
select {
    border: 1px solid var(--input-border-color);
    background-color: $white;
    border-radius: 2px;
    color: var(--input-color);
    padding: 13px 12px;
    appearance: none;
    caret-color: $black;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
           
    &:not(:empty),
    &:not(:placeholder-shown) {
        border: 1px solid var(--input-border-color);
    }

    &:focus {
        border: 1px solid var(--input-border-color-focus);
        border-image-source: $primary-gradient;
        border-image-slice: 1;
        background-color: var(--input-background-color-focus);
    }

    &:not(:first-of-type) {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &:-webkit-autofill {
        /* stylelint-disable-next-line declaration-no-important */
        box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
        /* stylelint-disable-next-line declaration-no-important */
        background-color: var(--input-background-color) !important;
    }
}

input {
    height: 48px;
}

textarea {
    width: 300px;

    @include mobile {
        width: 100%;
    }
}

[type='text'] {
    @include mobile {
        width: 100%;
    }
}
